// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section{
    z-index: 110;
    position: relative;
    width: 100%;
}
.container{
    margin-top: 6em;
    margin-bottom: 4em;
    text-align: center;
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
}
.container p{
    font-size: 21px;
    font-weight: 600;
    text-align: center;
    color: rgba(35, 31, 32, 0.5);
    font-family: 'Poppins',sans-serif;
}
.row-logo{
    margin-top: 2em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}
.partners-logo{
    width: 200px;
    height: 50px;
}
@media (max-width:768px) {
    .partners-logo{
        width: 50px;
        height: 30px;
    }
}`, "",{"version":3,"sources":["webpack://./src/css/Feature.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,WAAW;AACf;AACA;IACI,eAAe;IACf,kBAAkB;IAClB,kBAAkB;IAClB;;8BAE0B;AAC9B;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,4BAA4B;IAC5B,iCAAiC;AACrC;AACA;IACI,eAAe;IACf,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,YAAY;AAChB;AACA;IACI;QACI,WAAW;QACX,YAAY;IAChB;AACJ","sourcesContent":[".section{\n    z-index: 110;\n    position: relative;\n    width: 100%;\n}\n.container{\n    margin-top: 6em;\n    margin-bottom: 4em;\n    text-align: center;\n    /* display: flex;\n    flex-direction: column;\n    justify-content: center; */\n}\n.container p{\n    font-size: 21px;\n    font-weight: 600;\n    text-align: center;\n    color: rgba(35, 31, 32, 0.5);\n    font-family: 'Poppins',sans-serif;\n}\n.row-logo{\n    margin-top: 2em;\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    text-align: center;\n}\n.partners-logo{\n    width: 200px;\n    height: 50px;\n}\n@media (max-width:768px) {\n    .partners-logo{\n        width: 50px;\n        height: 30px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
