import React from "react";

const Body = () => {
  return (
    <div className="profit-main">
      <div className="profit-second-privacy">
        <div className="privacy-main">
          <div className="privacy-first">
            <h3>Terms Of Service</h3>
          </div>
          <div className="privacy-second">
            <h2>TERMS OF SERVICE</h2>
            <p>As of December 01, 2023, Updated in Miami Florida </p>


            <h3 style={{ textTransform: "uppercase" }}>Introduction</h3>
<p>Welcome to Podtoks. By using our app, you agree to comply with the following Terms and Conditions. If you do not agree with these terms, please do not use our services.</p>

<h3 style={{ textTransform: "uppercase" }}>Code of Conduct and Zero-Tolerance Policy</h3>
<p>We strive to maintain a respectful, safe, and inclusive environment for all users. By using our platform, you agree not to post, share, or engage in any content that is:</p>
<ul>
  <li>Offensive, harmful, or abusive to others, including hate speech, harassment, and threats.</li>
  <li>Explicit, obscene, or sexually suggestive.</li>
  <li>Defamatory, discriminatory, or promotes violence.</li>
</ul>
<p>Any violation of these terms will result in immediate action, including removal of content and suspension or termination of your account.</p>

<h3 style={{ textTransform: "uppercase" }}>Content Moderation</h3>
<p>To ensure a positive experience for all users, we utilize content moderation tools to detect and filter objectionable content. This includes:</p>
<ul>
  <li>Automated Content Filters: We use third-party tools (such as Microsoft Content Moderator and Google Perspective API) to automatically detect harmful text and images before they are posted.</li>
  <li>Manual Review: Content flagged by users will be reviewed by our moderation team within 24 hours. If deemed inappropriate, it will be removed immediately.</li>
</ul>
<p>We reserve the right to remove any content that violates our policies at our discretion.</p>

<h3 style={{ textTransform: "uppercase" }}>Reporting Objectionable Content</h3>
<p>If you come across content that violates our Code of Conduct, you can report it to us through the following mechanisms:</p>
<ul>
  <li><strong>Flagging System:</strong> Next to each post, comment, or user profile, you will find a "Report" button. Clicking this will allow you to specify the reason for your report (e.g., "Hate Speech," "Harassment," "Nudity," etc.).</li>
  <li><strong>Categories for Reporting:</strong> When reporting content, you will be asked to choose a category that best fits the violation. These include, but are not limited to:
    <ul>
      <li>Hate Speech or Harassment</li>
      <li>Nudity or Explicit Content</li>
      <li>Spam or Scams</li>
      <li>Other Violations</li>
    </ul>
  </li>
</ul>
<p>All reports will be reviewed within 24 hours by our moderation team. If the content is found to be in violation, appropriate action will be taken.</p>

<h3 style={{ textTransform: "uppercase" }}>Blocking Users</h3>
<p>To protect yourself from abusive or unwanted interactions, you can block other users. Blocking a user will prevent them from:</p>
<ul>
  <li>Sending you messages</li>
  <li>Commenting on your posts</li>
  <li>Interacting with your profile</li>
</ul>
<p>Users who are blocked will not be notified, and they will no longer have access to any interactions with you.</p>

<h3 style={{ textTransform: "uppercase" }}>Developer Action on Objectionable Content Reports</h3>
<p>We take your safety seriously. When content is flagged as objectionable, our team will act within 24 hours to review and, if necessary, remove the content. If the flagged content violates our policies, it will be removed. The user who posted the content may face consequences, including warnings, temporary suspension, or permanent account ban.</p>
<p><em>Example Action:</em> A user repeatedly posting hateful language may be permanently banned after multiple reports and warnings.</p>

<h3 style={{ textTransform: "uppercase" }}>Appeals Process</h3>
<p>If you believe that your content was wrongfully flagged or removed, you may appeal the decision. To do so:</p>
<ul>
  <li>Submit an appeal via the "Help" section of the app.</li>
</ul>
<p>Our team will review the appeal and respond within 48 hours. We will provide a transparent explanation for any actions taken regarding your content or account.</p>

<h3 style={{ textTransform: "uppercase" }}>Privacy and Data Collection</h3>
<p>Please note that we may collect data on your reported content and actions taken. This data is used only for moderation purposes and is subject to our Privacy Policy.</p>

<h3 style={{ textTransform: "uppercase" }}>Changes to Terms and Conditions</h3>
<p>We reserve the right to update or change these Terms and Conditions at any time. Any changes will be communicated via email or a notification within the app.</p>





            <h3>SCOPE, ACCEPTANCE, AND MODIFICATION OF TERMS</h3>
            <p>
              These Terms of Service (“<strong>Terms of Service</strong>”)
              governs your use of the PodToks websites,{" "}
              <a href="https://podtoks.com/" style={{ textDecoration: "none" }}>
                www.podtoks.com
              </a>{" "}
              plus additional websites which may or may not be created in the
              future (the “Sites”), and the PodToks mobile application (the “
              <strong>App</strong>”) (collectively, the Site and App are the
              “Platform”), which is operated by Podtoks, LLC, which has its
              address is at 78 SW 7th St, Miami, FL 33130 (“
              <strong>Company</strong>”, “<strong>we</strong>”, “
              <strong>us</strong>”, or “ <strong>our</strong>”).
            </p>
            <div>
              In some instances, both these Terms and separate terms setting
              forth additional conditions may apply to a service or product
              offered via the Platform (“<strong>Additional Terms</strong>”).
              For example, the{" "}
              <a href="https://podtoks.com/communityguidelines">
                Podtok Community Guidelines
              </a>{" "}
              govern your interaction with other Platform users. To the extent
              there is a conflict between these Terms and any Additional Terms,
              the Additional Terms will control unless the Additional Terms
              expressly state otherwise.
            </div>
            <p>
              By downloading, using, creating an account, registering, or
              otherwise accessing content on the Platform, you acknowledge and
              accept these Terms, including the binding arbitration (if
              applicable to you) and dispute terms in Section 12, where
              applicable, and any applicable Additional Terms. Please also
              review the{" "}
              <a href="https://podtoks.com/privacy">Privacy Policy</a>, which
              describes our data practices with respect to personal information
              obtained through the Platform. These Terms set forth a legally
              binding agreement between you and the Company and govern your use
              of the Platform. To the extent permissible by law, these Terms
              limit the Company’s liability to you and require you to indemnify
              us and to settle certain disputes through individual arbitration.
              Please note that these Terms do not affect your mandatory
              statutory rights under applicable laws, to the extent that such
              rights apply to you and cannot be limited or excluded.
              <strong>
                If you do not agree to these Terms and any Additional Terms, do
                not use the Platform.
              </strong>
            </p>
            <div>
              Changes to these Terms will be in effect as of the “Last Updated”
              date at the top of this page. Your continued use of the Platform
              after the “Last Updated” date constitutes your acceptance and
              agreement to such changes. If these Terms materially change, we
              may provide reasonable notice in addition to changing the “Last
              Updated” date. You are encouraged to review these Terms regularly.
            </div>
            <p>
              If you access, register an account, or otherwise use the Platform
              you represent you are at least the age of majority in your
              jurisdiction. If you are under the age of majority, you represent
              that if you access the Platform, you will do so under the
              supervision of a legal guardian who agrees to be bound to these
              Terms on your behalf. The Platform is not intended for children
              under the age of 13 and we do not knowingly collect personal
              information online from individuals under age 13. If we become
              aware that we have obtained personal information from a child
              under age 13 we will delete such information in accordance with
              applicable law.
            </p>
            <h3>LICENSES</h3>
            <p>
              <strong>A. Intellectual Property.</strong> Company owns or has
              rights to the Platform, and certain materials, processes, and
              methods relating to the Platform, , including, but limited to, the
              layout, information, databases, articles, posts, text, typefaces,
              data, files, images, scripts, designs, graphics, instructions,
              illustrations, photographs, sounds, pictures, videos, advertising
              copy, designs, URLs, technology, software, interactive features,
              formatting, “look and feel” of the Platform, business processes,
              procedures, techniques, methods, algorithms, machine learning,
              coordination, selection, arrangement, and enhancement, including
              all copyrights, patents, trademarks, service marks, trade names,
              trade secrets, and all other intellectual property rights therein
              (together the “<strong>Platform IP</strong>”).. All rights, title,
              and interest in and to the Platform IP that Company makes
              available via the Platform are the property of Company, or our
              licensors or certain other parties, and are protected by U.S. and
              international copyright, trademark, trade dress, and/or other
              intellectual property rights and laws to the fullest extent
              possible.
            </p>
            <div>
              <strong>B. Limited License.</strong> Subject to your strict
              compliance with these Terms, Company grants you a limited,
              non-exclusive, revocable, non-commercial, non-assignable,
              personal, and non-transferable license to download, display, view,
              use, or play the Platform on a personal device your personal,
              non-commercial use only. No license is granted to Platform IP
              except as reasonably necessary for the foregoing limited license
              to the Platform. The foregoing limited license does not give you
              any ownership of, or any other intellectual property interest in,
              any of the Platform IP. Your unauthorized use of the Platform IP
              may violate copyright, trademark, privacy, publicity,
              communications, and other laws, and any such use may result in
              your personal liability, including potential criminal liability.
            </div>
            <p>
              <strong>
                C. License in Relation to Your User-Generated Content.
              </strong>
              You may be able to submit your own content to the Platform, such
              as video clips, sound recordings, live recording public comments,
              private messages, and other works created or submitted by you
              (collectively, “<strong>User-Generated Content</strong>” or “
              <strong>UGC</strong>”). <br />
              Subject to any third-party right in any preexisting content or
              materials included in your UGC, including, without limitation, any
              Platform IP, you retain all right, title and interest in and to
              any UGC that you upload onto the Platform. You hereby grant to
              Company and its affiliates, successors, licensees, and assigns, a
              non-exclusive, worldwide, perpetual, irrevocable, royalty-free,
              fully paid, transferrable, assignable, sublicensable (through
              multiple tiers) right and license to use, copy, reproduce,
              display, broadcast, advertise, promote, market, distribute,
              publish, modify, adapt, make derivative works of, make collective
              works with, translate, sublicense, and otherwise exploit, in any
              and all media now known or later developed, via the Platform or by
              other means, at Company’s own discretion without your prior
              inspection or review, (i) your UGC (including all copyrights and
              other intellectual property rights related thereto or contained
              therein) and (ii) your name, image, voice, likeness, biographical
              material, and any other right of publicity/privacy held by you in
              connection with such UGC (as well as any other individual[s]
              featured in your UGC). As permitted by applicable law, you hereby
              waive, and you agree to waive, any moral rights (including
              attribution and integrity) that you may have in any UGC, even if
              it is altered or changed in a manner not agreeable to you. To the
              extent any moral rights are not transferable or assignable, you
              hereby waive and agree never to assert any and all moral rights,
              or to support, maintain or permit any action based on any moral
              rights that you may have in or with respect to any UGC.
            </p>
            <div>
              This includes you granting Company the right to reproduce UGC,
              such as sound recordings (and make mechanical reproductions of the
              musical works embodied in such sound recordings), and publicly
              perform and communicate to the public UGC, including sound
              recordings (and the musical works embodied therein), all on a
              royalty-free basis to any party, including, but not limited to, a
              sound recording copyright owner, a musical work copyright owner, a
              performing or sound rights organization, any unions or guilds, and
              engineers, producers or other royalty participants involved in the
              creation of the UGC. To the extent applicable, you must notify
              interested parties of the royalty-free license you grant through
              these Terms to us. If you have assigned your rights to another
              party, then you must obtain the consent of such party to grant the
              royalty-free license(s) set forth in these Terms.
            </div>
            <p>
              You hereby represent and warrant to Company that: (i) all UGC
              uploaded by or on your behalf onto the Platform is your original
              work of authorship for which you are the sole and exclusive owner,
              or that you have obtained all licenses, consents, releases,
              authorizations, and other permissions from the applicable
              third-party owner thereof as may be necessary to grant Company the
              license to use such UGC as described in these Terms, and (ii)
              Company’s and/or any other user’s or sublicensee’s use of the UGC
              as permitted hereunder does not, and will not, violate any
              applicable laws or the rights of any third-party, including,
              without limitation, any right of publicity, right of privacy,
              copyright, patent, trademark, or other intellectual property right
              or any proprietary right.
            </p>
            <div>
              You acknowledge and agree that Company may generate revenues,
              increase goodwill, or otherwise increase the value of Company,
              from your use of the Platform and any UGC, and you will have no
              right to share in any such revenue, goodwill or value whatsoever.
              You further acknowledge that you have no right to monetize or
              receive any income or other consideration from any UGC upload onto
              the Platform or to external parties via the Platform, or the use
              thereof by Company and/or other users.
            </div>
            <p>
              You are solely and separately responsible for obtaining any
              necessary rights, clearances, permissions or authorizations for
              posting to any external party service. Additionally, you
              acknowledge and agree that any UGC created using the Platform
              uploaded to an external service shall be subject to the terms and
              conditions, policies, and rules of that external party and you
              represent and warrant to Company that you shall at all times be
              and remain in compliance with such terms, policies and rules.
            </p>
            <div>
              <strong>D. License to UGC of Others.</strong> You acknowledge and
              agree that all rights to UGC will remain with the applicable
              owners of any work used to create such UGC. Notwithstanding
              anything to the contrary contained herein, you acknowledge and
              agree that Company does not grant you, and you do not obtain, any
              copyright or other interest in and to any Materials, UGC belonging
              to others (including UGC that you incorporate into your own UGC),
              or other content owned by others. Company is not responsible for
              putting you in touch with any other user or third-party owner for
              purposes of obtaining permissions related to using UGC.
            </div>
            <p>
              No license is granted, without the separate permission of the
              applicable owner thereof, to You to: (i) use any aspect of any
              UGC, including sound recordings or musical works, in a manner that
              is not expressly permitted by these Terms; or (ii) modify, change,
              or adapt the fundamental character, such as lyrics, of any UGC,
              including sound recordings or musical works.{" "}
            </p>
            <div>
              <strong>E. Trademarks.</strong> The Company trademarks, service
              marks, and logos (the “<strong>Company Trademarks</strong>”) used
              and displayed on the Platform are Company’s registered and
              unregistered trademarks or service marks. Other product and
              service names located on the Platform may be trademarks or service
              marks owned by third parties (the “
              <strong>Third-Party Trademarks</strong>,” and, collectively with
              the Company Trademarks, the “<strong>Trademarks</strong>”). Except
              as otherwise permitted by law, you may not use the Trademarks to
              disparage Company or the applicable third-party, Company’s or a
              third- party’s products or services (including, without
              limitation, the Platform), or in any manner (using commercially
              reasonable judgment) that may damage any goodwill in the
              Trademarks. You may not use any Trademarks as part of a link to or
              from the Platform without Company’s prior express written consent.
              All goodwill generated from the use of any Trademark will inure
              solely to the benefit of Company or the applicable third-party
              owner thereof.
            </div>
            <p>
              <strong>F. Reservation of Rights.</strong> All rights not
              expressly granted to you are reserved by Company and its licensors
              and other parties. No right or license may be construed, under any
              legal theory, by implication, estoppel, industry custom, or
              otherwise. Any unauthorized use of the Platform, UGC, and
              Materials for any purpose is prohibited.
            </p>
            <h3>REGISTERED ACCOUNTS</h3>
            <div>
              If you register an account on the Platform, you are solely
              responsible and liable for the security and confidentiality of
              your access credentials and for restricting access to your
              account. We may reject the use of any password, username, or email
              address for any reason in our reasonable discretion, including
              those that are profane, offensive, inappropriate, or otherwise
              violation these Terms or Additional Terms. You are solely
              responsible for your registration information and for updating and
              maintaining it. You will immediately notify us of any unauthorized
              use of your account, password, or username, or any other breach of
              security, but will remain responsible for any unauthorized use
              thereafter. You will not sell, transfer, or assign your account or
              any account rights. You agree to create only one (1) unique
              registered account and that you shall be the sole authorized user
              of your Account. You agree to not re-create any new or additional
              account in the event your original account is suspended or
              terminated by the Company.{" "}
            </div>
            <p>
              The Platform may allow you to register for an account using your
              external-party social media credentials. Such external parties may
              provide us information about you related to your account
              registered with the external party so the Platform can pre-fill
              certain information for you during the Platform registration
              process. In addition, in some cases, using external credentials
              may allow us to access your social media contacts. In any case,
              when you register on the Platform, log into the Platform, or
              otherwise access the external-party services using external-party
              integrations on the Platform, you agree to allow such external
              parties to send us information relevant to you, subject to their
              terms and policies, and enable us to use such information in
              accordance with these Terms and our{" "}
              <a href="https://podtoks.com/privacy">Privacy Policy.</a>{" "}
            </p>
            <div>
              We do not review accounts for authenticity and are not responsible
              for any unauthorized accounts that may appear on the Platform. For
              any dispute as to account creation or authenticity, we shall have
              the sole right, but are not obligated, to resolve such dispute as
              we determine appropriate, without notice.{" "}
            </div>
            <p>
              We do not review accounts for authenticity and are not responsible
              for any unauthorized accounts that may appear on the Platform. For
              any dispute as to account creation or authenticity, we shall have
              the sole right, but are not obligated, to resolve such dispute as
              we determine appropriate, without notice.{" "}
            </p>
            <h3>USER-GENERATED CONTENT</h3>
            <div>
              Subject to Section 2.C, the Platform may enable users to submit
              UGC. You acknowledge and agree that the Company may establish
              general practices and limits concerning UGC, including without
              limitation, the retention period for UGC. You acknowledge that
              Company reserves the right to change these general practices and
              limits at any time, in its sole discretion, with or without notice
              to you.
            </div>
            <p>
              You must not upload any UGC that you consider to be confidential
              or proprietary. UGC is prohibited from featuring any person that
              has not given you express permission (or in the case of a person
              under the age of majority of the jurisdiction of their residence,
              the express permission from that underage person’s parent or legal
              guardian) to include such person in such UGC and where such person
              (or in the case of a person under the age of majority of the
              jurisdiction of their residence, such underage person’s parent or
              legal guardian) has not granted you all rights necessary or
              required for you to grant Company the license described in Section
              2.C.
            </p>
            <div>
              You may be allowed to submit UGC in the form of live stream audio
              or audio-visual content on a “real-time” basis. In certain cases,
              live content may only be made available via the Platform during
              the “real-time” period in which the live stream is actually taking
              place and will not be accessible on the Platform or be available
              to other users after this time period.{" "}
            </div>
            <p>
              We reserve the right to reject, refuse to post, edit, modify,
              remove all or a portion of any UGC if it violates these Terms,
              including prohibitions and restrictions described in Section 5,
              violates applicable Additional Terms, violates the rights of
              others (e.g., copyright violations), violates the law, or includes
              any content that Company otherwise determines, in its reasonable
              discretion, to be inappropriate. You agree that Company has no
              responsibility or liability for the blocking, deletion or failure
              to store or make available any UGC maintained or transmitted by
              the Platform.{" "}
            </p>
            <div>
              You acknowledge and agree that Company is not under any obligation
              to monitor UGC posted on the Platform and cannot and does not take
              any responsibility for such UGC. Company does not endorse, support
              or guarantee the completeness, accuracy or reliability of any UGC
              or communications posted via the Platform or opinions expressed
              therein. Use of or reliance on any UGC or communications posted by
              users via the Platform is at your own risk.
            </div>
            <p>
              You acknowledge and agree that certain information about you may
              be publicly viewable by others on the Platform, subject to your
              privacy settings and users’ purchase access limitations, for
              example, your profile information, posted video content, videos
              you like, users you follow, your followers, and who likes your
              videos.
            </p>
            <h3>LIMITATIONS ON PLATFORM USE</h3>
            <p>
              UGC belonging to another user may not be used, modified,
              reproduced, duplicated, copied, published, distributed,
              downloaded, sold, resold, transformed, redesigned, reconfigured,
              retransmitted, or otherwise exploited by you for any purpose
              without the express prior written consent of the applicable User,
              and/or the applicable third-party owner.
            </p>
            <div>
              In addition to adhering to the{" "}
              <a href="https://podtoks.com/communityguidlines">
                Community Guidelines
              </a>
              , you agree that you will not:{" "}
            </div>
            <ul>
              <li>
                Use the Platform for any commercial or political purpose,
                without prior written agreement in each instance from Company,
                including posting affiliate links or UGC related to fraudulent,
                counterfeit, or stolen items, and conducting sweepstakes,
                contests, pyramid schemes, or chain letters.{" "}
              </li>
              <li>
                Engage in, or solicit others to engage in, any activities
                through or in connection with the Platform that: harm, or
                attempt to harm, any individuals or entities, especially minors;
                are unlawful, offensive, obscene, lewd, lascivious, violent,
                threatening, harassing, stalking, scandalous, inflammatory,
                defamatory, pornographic, profane or abusive; promotes or
                condones terrorism, violence, or illegal behavior; violate any
                right of any other party (including intellectual property);
                could constitute or encourage conduct that would be considered a
                criminal offense, give rise to civil liability, or otherwise
                violate any law; or are otherwise objectionable to Company.
              </li>
              <li>
                Misrepresent your identity or affiliation with any person,
                including endorsements, or give the impression that any UGC
                emanates from or is endorsed by Company, if this is not the
                case.
              </li>
              <li>
                Publish or make available, or request or solicit individuals to
                publish or make available, any personal contact or location
                information (e.g., phone number, email address, physical
                address, third-party platform social media handles, location
                information), via the Platform without authorization from that
                individual.
              </li>
              <li>
                Impersonate another person or user or knowingly allow any other
                person or entity to use your identification or account to post
                or view comments on, or otherwise utilize, the Platform.
              </li>
              <li>
                Decompile, disassemble, reverse engineer, or attempt to
                reconstruct, identify, or discover any source code, underlying
                ideas, underlying user interface techniques, hidden text, or
                algorithms of the Platform by any means whatsoever or modify any
                software (in either object code or source code) or other
                products, services, or processes accessible through any portion
                of the Platform.
              </li>
              <li>
                Engage in any activity that interferes with a user’s access to
                the Platform or the proper operation of the Platform, or
                otherwise causes harm to the Platform, Company, or other users
                of the Platform.
              </li>
              <li>
                Interfere with or circumvent any security feature (including any
                digital rights management mechanism, device, or other content
                protection or access control measure) or any other feature that
                restricts or enforces limitations on use of or access to the
                Platform or the Materials.
              </li>
              <li>
                Harvest, scrape, or otherwise collect or store any information,
                including personal information, from the Platform or of other
                users of the Platform, including email addresses.
              </li>
              <li>
                Attempt to gain unauthorized access to the Platform or other
                computer systems or networks connected to the Platform; attempt
                to probe, scan, or test the vulnerability of a system or
                network; or attempt to breach security or authentication
                measures without proper authorization.
              </li>
              <li>
                Use the Platform to transmit information that is in any way
                false, fraudulent, or misleading.
              </li>
              <li>
                Monitor, gather, copy, or distribute the Materials (except as
                may be a result of standard search engine activity or use of a
                standard browser) by using any robot, rover, bot, spider,
                scraper, crawler, spyware, engine, device, software, extraction
                tool, or any other automatic device, utility, or manual process
                of any kind.
              </li>
              <li>
                Remove any copyright, trademark, or other intellectual property
                or proprietary notices or legends contained in the Materials.
              </li>
              <li>
                Copy, modify, adopt, adapt, reproduce, archive, sell, lease,
                rent, exchange, create derivative works from, publish by hard
                copy or electronic means, publicly perform, display,
                disseminate, distribute, broadcast, retransmit, circulate, or
                transfer to any other party or on any Other Online Service, or
                otherwise use or exploit the Materials in any way for any
                purpose except as specifically permitted by these Terms or with
                the prior written consent of Company.
              </li>
              <li>
                Insert any code or product to manipulate the Platform or
                Materials in any way that adversely affects Company or attempt
                to interfere with service to any user, host, or network.
              </li>
              <li>
                Infringe our rights (including intellectual property rights) or
                those of any third party in relation to your use of the Platform
                (to the extent that such use is not licensed under these Terms).
              </li>
              <li>
                Breach any technology or control or export laws and regulations
                that apply to the technology used or supported by the Platform.
              </li>
              <li>
                Download any UGC or file posted by another user that a user
                knows, or reasonably should know, cannot be legally distributed
                through the Platform.
              </li>
              <li>
                Use, transfer, distribute or dispose of Materials or UGC in any
                manner that could compete with the business of Company.
              </li>
              <li>Otherwise violate these Terms.</li>
            </ul>
            <p>
              You agree that if you submit any personal information of another
              person to Company or to the Platform, you represent that you are
              authorized to provide that individual’s personal information to
              Company. You are solely responsible for all of your interactions
              with the Platform and other Users/User Content on or through the
              Platform.
            </p>
            <h3>SUSPENSION OR TERMINATION OF PLATFORM ACCESS</h3>
            <div>
              We reserve the right to investigate violations of these Terms or
              Additional Terms, including violations of Section 5 or our
              <a href="https://podtoks.com/communityguidelines">
                Community Guidelines
              </a>
              . We may, for any reason to the extent possible under law, take
              any action we deem appropriate in relation to users, including,
              but not limited to:
            </div>
            <ul>
              <li>
                or remove, block, hide or otherwise delete any UGC that you
                upload to the Platform,
              </li>
              <li>
                disable your account, suspend or terminate your use of, or
                access to, the Platform (either in whole or in part)
              </li>
              <li>
                reporting any suspected unlawful activity to law enforcement
                officials, regulators, or other third parties and disclosing any
                information necessary or appropriate to such persons or
                entities.
              </li>
            </ul>
            <div>
              Without limiting Company’s other rights, Company may, in
              appropriate circumstances, terminate a repeat infringer’s access
              to the Platform and any other online service owned or operated by
              Company.
            </div>
            <p>
              If we terminate, limit, or suspend your right to use the Platform,
              you are prohibited, without Company’s prior written consent, from
              registering and creating a new account under your name, a fake or
              borrowed name, or the name of any third-party, even if you may be
              acting on behalf of the third-party.  In the event your right to
              use the Platform terminated, limited, or suspended, these Terms
              will remain in effect and enforceable against you.
            </p>
            <div>
              Any licenses granted to you in these Terms, such as those
              described in Section 2, terminate automatically upon any
              unauthorized use of the Platform and Company will take appropriate
              investigative and legal action for any illegal or unauthorized use
              of the Platform.
            </div>
            <p>
              Please note that, deleting or uninstalling the App mobile device
              without deleting your account will not delete your account,
              including UGC related to your account.  If you wish to delete your
              account, you may do so through your account settings, if
              available, or by contacting us.
            </p>
            <div>
              If you encounter any UGC that violates, or learn of any user who
              has violated, these Terms, Additional Terms, the rights of you or
              others, or applicable law, please email Company at{" "}
              <a href="mailto:support@podtoks.com">support@podtoks.com</a>  or
              inform us through the applicable reporting functionality offered
              via the Platform.  Company may, but is not obligated to, take any
              of the actions set forth in these Terms against users who are
              reported.
            </div>
            <h3>COPYRIGHT INFRINGEMENT</h3>
            <div>
              COPYRIGHT INFRINGEMENT In our reasonable discretion, we may remove
              content, including User-Generated Content, that may be infringing
              on another person’s intellectual property rights with or without
              notice to the potential infringer. In accordance with the U.S.
              Digital Millennium Copyright Act (DMCA) and other applicable law,
              Company has adopted a policy of terminating, in appropriate
              circumstances, the accounts of users who are deemed to be repeat
              infringers.
            </div>
            <p>
              If you own a copyright in a work (or represent such a copyright
              owner) and believe that your (or such owner’s) copyright in that
              work has been infringed by an improper posting or distribution of
              it through the Platform, then you may send us a written notice
              that must include all of the following:
            </p>
            <ul>
              <li>
                “Copyright Infringement Notice” in the heading or subject line.
              </li>
              <li>
                A description of the copyrighted work you believe to have been
                infringed.
              </li>
              <li>
                Identification of the material you believe to be infringing in a
                sufficiently precise manner to allow us to locate that material
                (e.g., a screenshot of the location where the material is
                located).
              </li>
              <li>
                Accurate and adequate information that we can use to contact you
                (including your full name, postal address, telephone number, and
                email address).
              </li>
              <li>
                A statement that you have a good faith belief that use of the
                copyrighted material is not authorized by the copyright owner,
                its agent, or the law.
              </li>
              <li>
                A statement by you, made under penalty of perjury, that all the
                information in your notice is accurate, and that you are the
                copyright owner (or, if you are not the copyright owner, then
                your statement must indicate that you are authorized to act on
                the behalf of the owner of an exclusive right that is allegedly
                infringed).
              </li>
              <li>Your physical or electronic signature.</li>
            </ul>
            <div>
              We will respond to notices of copyright infringement in accordance
              with the DMCA or other applicable laws protecting copyright in
              your jurisdiction. Our agent for notice of claims of copyright
              infringement for the Platform can be reached at:
            </div>
            <p>Copyright Compliance Department</p>
            <div>PodToks Inc.</div>
            <p>
              Email: 
              <a href="mailto:support@podtoks.com">support@podtoks.com</a>
            </p>
            <div>
              Company may elect to not respond to DMCA or other copyright
              infringement notices that do not substantially comply with all of
              the foregoing requirements, and we may elect to remove allegedly
              infringing material that comes to our attention via notices that
              do not substantially comply with the DMCA or other applicable
              copyright protection laws. Please note that the DMCA provides that
              any person who knowingly materially misrepresents an infringement
              may be subject to liability. We may send the information that you
              provide in your notice to the person who provided the allegedly
              infringing work. That person may elect to send us a
              counter-notification under the DMCA or other applicable copyright
              protection laws. Please note that the DMCA provides that any
              person who knowingly materially misrepresents that material or
              activity is infringing may be subject to liability.
            </div>
            <p>
              Certain UGC available on the Platform may have a “Report” link
              available, which will trigger a notice to us to institute a review
              and potential takedown of such UGC. You understand that we may
              forward your notification to the author of the allegedly
              infringing content for a counter statement. We also reserve the
              right to transmit a user’s contact data to a rights owner who has
              substantiated an infringement by such user for the purposes of
              enforcing its statutory rights.
            </p>
            <div>
              <strong>
                Counternotice to Restore User Content Removed for Alleged
                Copyright Infringement:
              </strong>
                If access on the Platform to a work that you submitted to
              Company is disabled or the work is removed as a result of a DMCA
              Copyright Infringement Notice, and if you believe that the
              disabled access or removal is the result of mistake or
              misidentification, then you may send us a DMCA
              Counter-Notification to the addresses above. Your DMCA
              Counter-Notification should contain the following information:
            </div>
            <ul>
              <li>
                a legend or subject line that says: “DMCA Counter-Notification”.
              </li>
              <li>
                a description of the material that has been removed or to which
                access has been disabled and the location at which the material
                appeared before it was removed or access to it was disabled
                (please include the full URL of the page(s), or screenshots, on
                the Platform from which the material was removed or access to it
                disabled).
              </li>
              <li>
                a statement under penalty of perjury that you have a good faith
                belief that the material was removed or disabled as a result of
                mistake or misidentification of the material to be removed or
                disabled.
              </li>
              <li>
                your full name, address, telephone number, email address, and
                the username of your account.
              </li>
              <li>
                a statement that you consent to the jurisdiction of the Federal
                District Court for the judicial district in which your address
                is located (or, if the address is located outside the U.S.A., to
                the jurisdiction of the United States District Court for the
                Southern District of New York), and that you will accept service
                of process from the person who provided DMCA notification to us
                or an agent of such person.
              </li>
              <li>your electronic or physical signature.</li>
            </ul>
            <div>
              Please note that the DMCA provides that any person who knowingly
              materially misrepresents that material or activity was removed or
              disabled by mistake or misidentification may be subject to
              liability.
            </div>
            <p>
              If we receive a DMCA Counter-Notification, then we may replace the
              material that we removed (or stop disabling access to it) subject
              to DMCA or other applicable law. You should be aware that we may
              forward the Counter-Notification to the party who sent us the DMCA
              Copyright Infringement Notice.
            </p>
            <h3>OTHER INTELLECTUAL PROPERTY INFRINGEMENT</h3>
            <div>
              Company reserves the right to remove any UGC that
              allegedly infringes another party’s trademark, service mark or
              other intellectual property right.  Company may terminate, in
              appropriate circumstances, users who are infringers of another
              party’s trademark or service mark, including repeat offenders.
            </div>
            <p>
              Notices to Company regarding any such alleged infringement should
              be directed to Company via email at: 
              <a href="mailto:support@podtoks.com">support@podtoks.com</a> and
              shall include your full name and address, along with details of:
              (i) the date and time it was posted; (ii) where it can be found on
              the Platform; (iii) the username of the person who posted it; (iv)
              reasons why the content should be deleted, along with evidence for
              this; and (v) copies of any communication with the person who
              posted it (if any). <br /> If we remove your UGC, you may receive
              a notification from us that includes the name and email address of
              the rights holder who made the report and/or the details of the
              report.  If you believe your UGC should not have been removed, you
              can follow up with the rights holder directly to try to resolve
              the issue.  Company does not provide a counter-notice or appeal
              mechanisms for claims of trademark infringement and disclaims any
              liability for claims you might make regarding mistaken or
              misidentified trademark infringement notice and takedown requests.
            </p>
            <h3>PROMOTIONS</h3>
            <div>
              We may, from time to time, offer a sweepstakes, contest, or
              similar promotion (“<strong>Promotion</strong>”) on the Platform
              that may be offered via the Platform may be governed by Additional
              Terms that may have rules such as eligibility requirements,
              including certain age or geographic area restrictions, terms and
              conditions governing the Promotion, use of User-Generated Content,
              and disclosures about how your personal information may be used.
              All such Promotions will be run at our sole discretion and we may
              activate, modify, suspend, terminate, or remove any Promotion at
              any time, with or without advance notification, in accordance with
              these Terms of Service and the applicable Promotion’s official
              rules. It is your responsibility to read these Additional Terms to
              confirm that you are eligible to participate, register, and/or
              enter, the Promotion. By participating in a Promotion, you will be
              subject to the applicable Additional Terms and you agree to comply
              with and abide by rules and the decisions of the identified
              sponsor(s).
            </div>
            <p>
              You may use the Platform to offer a Promotion, provided that you
              comply with all applicable laws and these Terms of Service.
              Without limiting the generality of the foregoing, your Promotion
              must: (i) have a set of official rules that governs participation
              in the Promotion and includes terms such as, by way of example
              only, (a) eligibility requirements, (b) a description of how to
              enter the Promotion; (c) a description of all prizes and their
              approximate retail value, (d) a statement of the number of winners
              to be selected, and (e) a description the winner selection method;
              (ii) include in the official rules a complete release of PodToks
              by each participant and a statement that the Promotion is in no
              way sponsored, endorsed, administered by or associated with
              PodToks ; (iii) require participants to disclose their
              participation in the Promotion, for example via a hashtag such as
              #SweepstakesEntry; (iv) identify all sponsors of the Promotion;
              and (v) not assert or attempt to assert ownership of any UGC
              posted to the Platform in connection with your Promotion (except
              to the extent required to protect your registered intellectual
              property). If you advertise your Promotion on the Platform, your
              advertising must also comply with all applicable laws. For the
              avoidance of doubt, you are solely responsible for, and we
              disclaim all liability in connection with, your Promotion,
              including without limitation any advertising for the Promotion.
            </p>
            <div>
              You may use the Platform to participate in a Promotion. Note that
              Promotions will be governed by official rules; it is your
              responsibility to read the official rules to determine your
              eligibility to participate. By participating in a Promotion, you
              agree that you will be subject to the applicable official rules
              and to comply with the rules and decisions of the applicable
              Promotion sponsor(s). If you choose to participate in a Promotion,
              you must disclose your participation in any posts you publish on
              the Platform in connection with the Promotion, for example via a
              hashtag such as #SweepstakesEntry, in addition to any other
              disclosures as may be required pursuant to the applicable official
              rules. For the avoidance of doubt, you are solely responsible for,
              and we disclaim all liability in connection with, your
              participation in any Promotion.
            </div>
            <p>
              <strong>Advertising & Sponsorships</strong> <br /> The Platform
              may contain advertising and sponsorships. All advertising and
              sponsorship opportunities will be subject to Additional
              Terms.  Advertisers and sponsors are solely responsible for
              ensuring that material submitted for inclusion on the Platform is
              accurate and complies with all applicable laws, including, without
              limitation, Federal Trade Commission (FTC) and Federal
              Communications Commission (FCC) rules, regulations and guidelines,
              as well as any applicable laws related to the advertiser’s or
              sponsor’s industry. Without limiting the generality of the
              foregoing, if an advertiser or sponsor engages influencers and/or
              affiliates, it is solely responsible for ensuring compliance with
              the FTC’s Guides Concerning the Use of Endorsements and
              Testimonials in Advertising. Company is not responsible for, and
              disclaims all liability in connection with, the advertisers’ or
              sponsors’ materials, including any errors or compliance with law,
              or for the acts or omissions of advertisers and sponsors.
            </p>
            <h3>EXTERNAL SERVICES</h3>
            <div>
              The Platform may include external-party content or links to
              websites, products, or other online services that are not owned or
              controlled by Company (collectively, “
              <strong>External Services</strong>”).  Company does not control
              any such External Services and is not responsible for their (i)
              availability or accuracy, or (ii) content, advertising, or
              products or services. Inclusion of any External Services on the
              Platform does not constitute or indicate Company’s endorsement
              thereof and Company shall not be liable or responsible for any
              External Services transmitted through the Platform.  You shall be
              subject to any additional terms and conditions of use, guidelines
              or rules applicable to any External Services that you access
              through the Platform.  By using the Platform, you expressly
              relieve and hold Company harmless from any and all liability
              arising from your use of any External Services, including any loss
              or damage incurred as a result of any dealings between you and any
              external parties, or as the result of the presence of such
              External Services on the Platform or the failure of such External
              Services to function as intended.
            </div>
            <h3>INDEMNIFICATION</h3>
            <div>
              To the extent permitted by applicable law, you agree to, and you
              hereby, defend (if requested by Company), indemnify, and hold the
              Company and their direct and indirect parents, subsidiaries,
              affiliates, and each of their respective employees, directors,
              members, managers, shareholders, agents, vendors, licensors,
              licensees, contractors, customers, successors, and assigns
              (collectively, “<strong>Company Parties</strong>”) harmless from
              and against any and all claims, damages, losses, costs,
              investigations, liabilities, judgments, fines, penalties,
              settlements, interest, and expenses (including  legal fees) that
              directly or indirectly arise from or are related to any claim,
              suit, action, demand, or proceeding made or brought against any
              Company Party, or on account of the investigation, defense, or
              settlement thereof, arising out of or in connection with: (i)
              User-Generated Content, including, but not limited to, any
              unauthorized use of UGC by any user; (ii) your use of the Platform
              and your activities in connection with the Platform, including
              interactions between users; (iii) your breach or alleged breach of
              these Terms or any applicable Additional Terms; (iv) your
              violation or alleged violation of any laws, rules, regulations,
              codes, statutes, ordinances, or orders of any governmental or
              quasi- governmental authorities in connection with your use of the
              Platform or your activities in connection with the Platform; (v)
              information or material transmitted through your device, even if
              not submitted by you, that infringes, violates, or misappropriates
              any intellectual property, publicity, privacy, or other right of
              any person or entity; (vi) any misrepresentation made by you; and
              (vii) the Company Parties’ use of the information that you submit
              to us (including your User-Generated Content) (all of the
              foregoing, “Claims and Losses”), except to the extent that such
              Claims and Losses have been directly caused by a Company Party’s
              willful negligence or recklessness.  You will cooperate as fully
              required by Company Parties in the defense of any Claims and
              Losses. Notwithstanding the foregoing, the Company Parties retain
              the exclusive right to settle, compromise, and pay any and all
              Claims and Losses. Company Parties reserve the right to assume the
              exclusive defense and control of any Claims and Losses. You will
              not settle any Claims and Losses without, in each instance, the
              prior written consent of an officer of a Company Party. This
              Section is not intended to limit any causes of action against us
              that you may have but are not waivable under applicable law.
            </div>
            <h3>AGREEMENT TO ARBITRATE DISPUTES</h3>
            <div>
              THE LAWS OF CERTAIN JURISDICTIONS DO NOT ALLOW FOR MANDATORY
              ARBITRATION OR CLASS ACTION WAIVERS. IF THESE LAWS APPLY TO YOU,
              SOME OR ALL OF THE PROVISIONS BELOW MAY NOT APPLY TO YOU AND YOU
              MAY HAVE ADDITIONAL RIGHTS.
            </div>
            <p>
              The Company operates in interstate commerce and this arbitration
              agreement (“ <strong>Agreement</strong>”) is a contract evidencing
              a transaction involving commerce that is governed by the Federal
              Arbitration Act (“FAA”), 9 U.S.C. §§1 et seq.  {" "}
              <strong>Please read this Agreement carefully.</strong>
            </p>
            <div>I. What Is Arbitration?</div>
            <p>
              Arbitration is a way to get speedy and relatively inexpensive
              resolution of disputes by submitting them to an arbitrator instead
              of the ordinary process of filing a lawsuit in a court of law. 
              Arbitration uses a neutral, trained arbitrator to resolve the
              dispute instead of a judge and jury.  By agreeing to resolve
              Disputes exclusively through binding arbitration, you and the
              Company agree that you and the Company are waiving the right to a
              trial in a court with a judge or jury.  Arbitration is binding —
              that is, the arbitrator’s decision is final.
            </p>
            <div>
              You and the Company agree that arbitration of disputes is a
              valuable benefit, the existence of which is a significant
              inducement for you to accept these Terms and to use the Services.
            </div>
            <p>II. What Is Covered?</p>
            <div>
              This Agreement applies to all past, present, and future legal
              disputes and legal claims between you and the Company that are now
              in existence or that may arise in the future, including, but not
              limited to legal disputes or legal claims arising out of or
              relating in any way to these Terms of Service, the Privacy Policy,
              Additional Terms, your use of the Sites, the App, the Platform
              relationship with the Company, your use of any of the Company’s
              products or services, and any federal, state, or local statute,
              law, rule, regulation or ordinance applicable to the relationship
              between you and the Company as to which a court would be
              authorized by law to grant relief if the claim were successful (“
              <strong>Dispute</strong>” or “<strong>Disputes</strong>”). You
              agree that the term “Dispute” in this Agreement will have the
              broadest meaning possible. If any Dispute between you and the
              Company would be covered by both this Agreement and any other
              arbitration agreement which is not expressly included in these
              Terms of Service, you agree that the terms of this Agreement will
              supersede the terms of any other arbitration agreement, and that
              all Disputes will be resolved pursuant to the terms of this
              Agreement. <br /> This Agreement also covers any Dispute between
              you and any officer, director, board member, agent, employee,
              affiliate of the Company, or third party if the Company could be
              liable, directly or indirectly, for such Dispute.
            </div>
            <p>
              If the parties have more than one Dispute between them, you and
              the Company agree to assert all such Disputes in a single
              arbitration so they may be resolved at the same time.
            </p>
            <div>
              The Agreement does not prevent either you or the Company from
              seeking emergency or temporary injunctive relief, such as a
              preliminary injunction or a temporary restraining order, which is
              sought strictly in aid of or to preserve an arbitrator’s
              jurisdiction and ability to conduct a meaningful arbitration of
              your Disputes under the Agreement.
            </div>
            <p>
              The Agreement does not prevent you or the Company from filing a
              claim or charge with an administrative agency. Additionally, you
              will not be retaliated against for challenging the validity of the
              Agreement.
            </p>
            <div>
              Nothing contained in this Agreement shall be construed to prevent
              or excuse you from first using the Company’s existing internal
              procedures for resolution of complaints before seeking
              arbitration, and this Agreement is not intended to be a substitute
              for the utilization of such procedures.
            </div>
            <p>
              For claims under the jurisdiction of federal, state, or local
              government agencies, you may initiate the arbitration process only
              after you have exhausted all required administrative remedies for
              claims that require such remedies.
            </p>
            <div>III. Claims May Be Brought in an Individual Capacity Only</div>
            <p>
              You and the Company agree that you and the Company will not: (1)
              file, join, opt-into, consent to, intervene in, or otherwise
              become a party in any lawsuit or court case that relates in any
              way to a Dispute, or (2) file, join, opt-into, consent to,
              intervene in, or otherwise become a party in any lawsuit, court
              case, or arbitration that is brought on a class, collective,
              representative, or aggregate basis that in any way relates to a
              Dispute.  The parties do not agree to arbitrate any Dispute on a
              class, collective, representative, or aggregate basis.
            </p>
            <div>
              THE PARTIES MAY BRING LEGAL CLAIMS ONLY IN THEIR INDIVIDUAL
              CAPACITY (NON-CLASS, NON-COLLECTIVE, NON-REPRESENTATIVE, AND NON-
              AGGREGATE BASIS).
            </div>
            <p>
              THERE SHALL BE NO RIGHT OR AUTHORITY FOR ANY DISPUTES TO BE
              ARBITRATED ON A CLASS, COLLECTIVE, REPRESENTATIVE, OR AGGREGATE
              BASIS.
            </p>
            <div>
              THE ARBITRATOR MAY NOT CONSOLIDATE PROCEEDINGS ON MORE THAN ONE
              PERSON’S OR ENTITY’S CLAIMS, AND MAY NOT OTHERWISE AUTHORIZE OR
              PRESIDE OVER ANY FORM OF A CLASS, COLLECTIVE, REPRESENTATIVE OR
              AGGREGATE PROCEEDING.
            </div>
            <p>
              Claims of two or more persons or entities may not be joined or
              consolidated in the same arbitration because the arbitrator may
              only hear individual claims and does not have the authority to
              hear claims on a class, collective, representative, or aggregate
              basis, or to award relief to anyone other than you and/or the
              Company in a single arbitration.  Notwithstanding any other clause
              contained in this Agreement, this Section III shall not be
              severable from the Agreement in any case in which the Dispute is
              brought as a class, collective, representative, or aggregate
              action.
            </p>
            <div>
              Notwithstanding any other clause contained in this Agreement, any
              challenge to the validity of Section III may be determined only by
              a court of competent jurisdiction and not by an arbitrator.
            </div>
            <p>IV. How Does Arbitration Work?</p>
            <div>In order to raise a Dispute:</div>
            <p>
              Step 1. If you want to raise a Dispute, you must submit a “Notice
              of Dispute” with any supporting documents or other information by
              email to
              <a href="mailto:support@podtoks.com">support@podtoks.com</a>
              . The “Notice of Dispute” must be in writing and include your
              name, address, contact information, email address, the facts
              regarding your Dispute, and the relief you are requesting from us.
              If the Company wants to raise a Dispute, the Company must send a
              “Notice of Dispute” to your last known address and/or email
              address.  Once a party receives a Notice of Dispute, you and we
              will attempt to resolve any Dispute through informal negotiation
              within forty-five (45) days from the date the Notice of Dispute is
              received. If an agreement cannot be reached within forty-five (45)
              days of receipt of the Notice of Dispute, you or the Company may
              initiate an arbitration proceeding as described below.  <br />{" "}
              Step 2. Arbitration. You and the Company agree that arbitration
              will be the sole and exclusive remedy to resolve any Dispute
              between us. The Federal Arbitration Act applies to this Agreement.
              By agreeing to arbitrate, you and the Company are giving up the
              right to litigate (or participate in as a party or class member)
              any and all Disputes in a court before a judge or jury. Instead, a
              neutral arbitrator will resolve all Disputes. The American
              Arbitration Association or any successor thereof (“AAA”) will
              administer the arbitration using the AAA Consumer Arbitration
              Rules in effect on the date the Arbitration is filed (“AAA
              Rules”). In the event the AAA Rules are inconsistent with this
              Agreement, this Agreement will prevail. AAA is independent from
              us, and you may obtain copies of the current AAA Rules, and other
              related materials, including forms and instructions for initiating
              arbitration, by contacting AAA at 1-800-778-7879 or by visiting {" "}
              <a href="http://www.adr.org/">www.adr.org</a>. The AAA Rules are
              subject to modification by AAA from time to time and the parties
              are responsible for reviewing the rules periodically.  Procedures
              not addressed by this Agreement or the AAA Rules will be resolved
              by agreement of the parties.  If the parties are unable to agree,
              the procedural issue will be determined by the arbitrator. All
              statutes of limitation that would apply if the controversy were
              resolved in court shall be applied and enforced by the
              arbitrator.  The award of the arbitrator shall be final, and
              judgment upon the award rendered may be entered in any court,
              state or federal, having jurisdiction.
            </p>
            <div>V. General</div>
            <p>
              Enforcement.  Other than a challenge to the validity of Section
              III of this Agreement, any dispute over this Agreement – the way
              it was formed, its applicability, meaning, enforceability, waiver
              of the right or obligation to arbitrate, or any claim that all or
              part of this Agreement is void or voidable – is subject to
              arbitration under this Agreement.  You or the Company may bring a
              motion in court to compel arbitration under this Agreement or to
              dismiss any lawsuit seeking to resolve Disputes that are covered
              by this Agreement.  In addition, either you or the Company may
              bring an action in court to enforce an arbitration award.
            </p>
            <div>
              Complete Agreement.  This Agreement is the full and complete
              agreement relating to the formal resolution of Disputes.
            </div>
            <p>
              Severability. Except as stated above in Section III, in the event
              any portion of this Agreement is deemed invalid, void, or
              unenforceable, the remainder of this Agreement will be valid and
              enforceable.
            </p>
            <div>
              No Modification. Notwithstanding any other provision in these
              Terms, this Agreement cannot be modified except in writing agreed
              to by both parties.
            </div>
            <p>
              If any court with competent jurisdiction or an arbitrator holds
              that the right to compel arbitration of a Dispute is
              unenforceable, then the Dispute must be brought exclusively in
              court as follows. YOU AND COMPANY AGREE THAT EACH MAY BRING CLAIMS
              AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT
              AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
              REPRESENTATIVE PROCEEDING. YOU AND COMPANY AGREE TO WAIVE ANY
              RIGHT TO A JURY TRIAL.
            </p>
            <div>
              YOU SHOULD READ THE PROVISIONS OF THIS AGREEMENT CAREFULLY, AS IT
              PROVIDES THAT VIRTUALLY ANY DISPUTE RELATED TO THE COMPANY MAY BE
              RESOLVED ONLY THROUGH BINDING ARBITRATION. ARBITRATION REPLACES
              THE RIGHT TO GO TO COURT, INCLUDING THE RIGHT TO A JURY TRIAL AND
              THE RIGHT TO PARTICIPATE IN A CLASS, COLLECTIVE, REPRESENTATIVE,
              OR AGGREGATE ACTION OR SIMILAR PROCEEDING.
            </div>
            <p>
              YOU ACKNOWLEDGE THAT YOU HAVE RECEIVED AND READ OR HAVE HAD THE
              OPPORTUNITY TO READ THIS ARBITRATION AGREEMENT. YOU UNDERSTAND
              THAT THIS ARBITRATION AGREEMENT REQUIRES THAT DISPUTES THAT
              INVOLVE THE MATTERS SUBJECT TO THE AGREEMENT BE SUBMITTED TO
              ARBITRATION PURSUANT TO THE ARBITRATION AGREEMENT RATHER THAN TO A
              JUDGE AND JURY IN COURT.
            </p>
            <div>
              YOU AGREE THAT YOU INTEND TO BE BOUND BY THE AGREEMENT AND
              SPECIFICALLY THE ARBITRATION PROVISIONS SET FORTH ABOVE.
            </div>
            <p>
              ARE MASS ARBITRATIONS PERMITTED?  “Mass Arbitrations” means and
              refers to when, during any rolling 52-week period, 100 or more
              arbitration demands asserting the same or similar Disputes are
              made and/or sought to be compelled against Company by the same
              lawyer(s) or law firm(s) and/or by one or more of several
              affiliated, associated or coordinated lawyers or law firms.  If,
              in its sole discretion, the Company believes that Mass
              Arbitrations are being asserted against it, then it shall give
              written notice to you and the Arbitrator (if appointed) in each of
              the arbitration demands at issue.  Upon giving such notice,
              Company shall not have any further obligation to arbitrate those
              arbitration demands, and any arbitration proceedings shall be
              automatically stayed, pending Company seeking a determination from
              a court of competent jurisdiction that the arbitrations at issue
              are Mass Arbitrations.  Company will not be required to pay any
              arbitration fees as a condition of and while seeking such a court
              determination or any appeal thereof.  Should a court of competent
              jurisdiction hold that any arbitration demand made or sought to be
              compelled under this Agreement is one of such Mass Arbitrations,
              then the Company will not be required to arbitrate the Disputes. 
              Instead, those Mass Arbitrations will be litigated in a court of
              competent jurisdiction.  To the extent that you are part of a Mass
              Arbitration, you consent and agree to the personal and subject
              matter jurisdiction of any court in which Company seeks a Mass
              Arbitrations determination that otherwise would have such
              jurisdiction over any of the persons who have filed a demand that
              is part of the Mass Arbitration involved in the court action
              notwithstanding this consent and agreement.
            </p>
            <h3>DISCLAIMER OF REPRESENTATIONS AND WARRANTIES</h3>
            <div>
              THE LAWS OF CERTAIN JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
              LIMITATION OF LEGAL WARRANTIES, CONDITIONS OR REPRESENTATIONS. IF
              THESE LAWS APPLY TO YOU, SOME OR ALL OF THE BELOW EXCLUSIONS OR
              LIMITATIONS MAY NOT APPLY TO YOU AND YOU MAY HAVE ADDITIONAL
              RIGHTS.
            </div>
            <p>
              AS PERMITTED BY APPLICABLE LAW, YOUR ACCESS TO AND USE OF THE
              PLATFORM IS AT YOUR SOLE RISK AND THE PLATFORM IS PROVIDED ON AN
              “AS IS”, “AS AVAILABLE”, AND “WITH ALL FAULTS” BASIS. To the
              fullest extent permissible by applicable law, Company Parties
              hereby disclaim and make no representations, warranties,
              endorsements, or promises, express or implied, in connection with,
              or otherwise directly or indirectly related to, without
              limitation, the Platform, Materials, User-Generated Content or
              other Company products or services, except as set forth below.
            </p>
            <div>
              COMPANY MAKES NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY,
              CURRENTESS, COMPLETENESS OR RELIABILITY OF ANY MATERIALS OR UGC
              PROVIDED THROUGH THE PLATFORM OR THIRD-PARTY CONTENT ON THE
              PLATFORM AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (I)
              ERRORS, MISTAKES, OR INACCURACIES OF CONTENT, (II) PERSONAL INJURY
              OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR
              ACCESS TO AND USE OF THE PLATFORM, (III) ANY UNAUTHORIZED ACCESS
              TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
              INFORMATION STORED THEREIN.  COMPANY DOES NOT WARRANT, ENDORSE,
              GUARANTEE OR ASSUME RESPONSIBILITY FOR ANY SERVICE ADVERTISED OR
              OFFERED BY A THIRD-PARTY THROUGH THE PLATFORM. COMPANY WILL NOT BE
              A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
              TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR
              SERVICES, OTHER THAN AS PROVIDED HEREIN.
            </div>
            <p>
              <strong>
                EXCEPT FOR ANY SPECIFIC WARRANTIES OR CONDITIONS PROVIDED
                HEREIN, OR IN APPLICABLE ADDITIONAL TERMS, OR AS OTHERWISE
                REQUIRED BY APPLICABLE LAW, THE COMPANY PARTIES HEREBY FURTHER
                DISCLAIM ALL WARRANTIES AND CONDITIONS, EXPRESS OR IMPLIED,
                INCLUDING THE WARRANTIES AND CONDITIONS OF MERCHANTABILITY,
                FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OR
                MISAPPROPRIATION OF INTELLECTUAL PROPERTY RIGHTS OF OTHER
                PARTIES, TITLE, CUSTOM, TRADE, QUIET ENJOYMENT, SYSTEM
                INTEGRATION AND FREEDOM FROM COMPUTER VIRUS. A GENERAL RELEASE
                DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR
                SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING
                THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY
                AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR. <br />{" "}
                NOTWITHSTANDING THE FOREGOING, THIS SECTION DOES NOT EXPAND OR
                LIMIT (I) ANY EXPRESS, WRITTEN PRODUCT WARRANTY OR RELATED
                DISCLAIMERS THAT ARE PROVIDED BY THE COMPANY PARTIES OR THEIR
                SUPPLIERS WITH REGARD TO A PHYSICAL PRODUCT SOLD BY THE COMPANY
                PARTIES TO YOU, OR ANY WARRANTY ON A PHYSICAL PRODUCT TO THE
                EXTENT REQUIRED BY APPLICABLE LAW; (II) THE COMPANY PARTIES’
                LIABILITY  FOR PERSONAL INJURY TO YOU CAUSED BY THE COMPANY
                PARTIES, TO THE EXTENT NOT WAIVABLE OR AS CANNOT BE LIMITED
                UNDER APPLICABLE LAW; OR (III) ANY OTHER CAUSE OF ACTION YOU MAY
                HAVE AGAINST THE COMPANY PARTIES THAT IS NOT WAIVABLE OR CANNOT
                BE LIMITED UNDER APPLICABLE LAW. <br />
                For the avoidance of doubt, nothing in this Section is intended
                to exclude or limit any rights, warranties, or guarantees
                implied by applicable law and which cannot be excluded or
                modified as a matter of law.
              </strong>
            </p>
            <h3>LIMITATIONS OF OUR LIABILITY</h3>
            <div>
              THE LAWS OF CERTAIN JURISDICTIONS DO NOT ALLOW THE LIMITATION OF
              LIABILITY OR CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR
              ALL OF THE BELOW EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU
              AND YOU MAY HAVE ADDITIONAL RIGHTS.
            </div>
            <p>
              <strong>
                TO THE EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO
                CIRCUMSTANCES WILL ANY OF THE COMPANY PARTIES BE RESPONSIBLE OR
                LIABLE FOR ANY LOSS OR DAMAGES OF ANY KIND
              </strong>
              , including personal injury or death or for any direct, indirect,
              economic, exemplary, special, punitive, incidental, or
              consequential losses or damages of any kind (including
              availability of the Platform and its contents and physical harm or
              injury from a purchase of a physical product), including without
              limitation loss of profits, in connection with, or otherwise
              directly or indirectly related to, without limitation, the
              Platform, Materials, User-Generated Content or other Company
              products or services, except, to the extent not waivable or
              permissible under applicable law. <br /> The foregoing limitations
              of liability will apply, to the fullest extent permissible by
              applicable law, even if any of the events or circumstances were
              foreseeable (unless the foreseeability is in relation to our
              breach of these Terms) and even if the Company parties were
              advised of or should have known of the possibility of such losses
              or damages, regardless of whether you bring an action based in
              contract, negligence, strict liability, or tort (including whether
              caused, in whole or in part, by negligence, acts of god,
              telecommunications failure, or destruction of the Platform).
            </p>
            <div>
              Nothing in these Terms excludes or limits our liability for any
              matter in respect of which it would be unlawful for us to exclude
              or restrict our liability. If we fail to comply with these Terms,
              we are responsible for loss or damage you suffer that is a
              foreseeable result of our breach of these Terms or our negligence,
              but we are not responsible for any loss or damage that is not
              foreseeable. Loss or damage is foreseeable if it is an obvious
              consequence of our breach or if it was contemplated by you and us
              at the time we entered into these Terms. We are not liable for any
              loss or damage suffered by you as a result of your breach of these
              Terms.
            </div>
            <p>
              We have no liability to you for any loss of profit, loss of
              business, business interruption, or loss of business opportunity.
            </p>
            <div>
              <strong>
                AS PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL COMPANY
                PARTIES’ TOTAL LIABILITY TO YOU, FOR ALL POSSIBLE DAMAGES,
                LOSSES, AND CAUSES OF ACTION IN CONNECTION WITH YOUR ACCESS TO
                AND USE OF THE PLATFORM AND YOUR RIGHTS UNDER THESE TERMS,
                EXCEED AN AMOUNT EQUAL TO THE AMOUNT YOU HAVE PAID COMPANY IN
                CONNECTION WITH THE TRANSACTION(S) THAT UNDERLIE THE CLAIM(S) OR
                ONE HUNDRED (100) UNITED STATES DOLLARS WHERE YOU HAVE MADENO
                PAYMENT TO COMPANY IN CONNECTION WITH THE UNDERLYING CLAIM.{" "}
                <br />
                   NOTWITHSTANDING THE FOREGOING, THIS SECTION DOES NOT EXPAND
                OR LIMIT (I) ANY EXPRESS, WRITTEN PRODUCT WARRANTY OR RELATED
                DISCLAIMERS THAT ARE PROVIDED BY THE COMPANY PARTIES OR THEIR
                SUPPLIERS WITH REGARD TO A PHYSICAL PRODUCT SOLD BY THE COMPANY
                PARTIES TO YOU, OR ANY WARRANTY ON A PHYSICAL PRODUCT TO THE
                EXTENT REQUIRED BY APPLICABLE LAW; (II) THE COMPANY PARTIES’
                LIABILITY  FOR PERSONAL INJURY TO YOU CAUSED BY COMPANY PARTIES
                TO THE EXTENT NOT WAIVABLE OR AS CANNOT BE LIMITED UNDER
                APPLICABLE LAW; OR (III) ANY OTHER CAUSE OF ACTION YOU MAY HAVE
                AGAINST THE COMPANY PARTIES THAT IS NOT WAIVABLE OR CANNOT BE
                LIMITED UNDER APPLICABLE LAW.
              </strong>{" "}
              <br /> ANY DISPUTE YOU HAVE WITH ANY THIRD-PARTY ARISING OUT OF
              YOUR USE OF THE PLATFORM, INCLUDING, BY WAY OF EXAMPLE AND NOT
              LIMITATION, ANY COPYRIGHT OWNER OR OTHER USER, IS DIRECTLY BETWEEN
              YOU AND SUCH THIRD-PARTY, AND YOU IRREVOCABLY RELEASE THE COMPANY
              PARTIES FROM ANY AND ALL CLAIMS, DEMANDS AND DAMAGES (ACTUAL AND
              CONSEQUENTIAL) OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN,
              ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH DISPUTES. You
              acknowledge that violation of these Terms may subject you to
              third-party claims and none of the rights granted to you in these
              Terms of Service may be raised as a defense against any
              third-party claims arising from any such violation.
            </div>
            <p>
              <strong>
                For the avoidance of doubt, nothing in this Section is intended
                to limit or exclude any liability which cannot be limited or
                excluded under applicable law.
              </strong>
            </p>
            <h3>GENERAL PROVISIONS</h3>
            <div>
              <strong>Platform Availability.</strong> Company in its reasonable
              discretion and without advance notice or liability, may
              immediately suspend or terminate the availability of the Platform
              and/or Materials, in whole or in part, for any reason. Information
              collected through the Platform (e.g., your account information)
              may be synchronized from time to time between the App and the
              Sites, but we do not guarantee accurate or full synchronization.
              To the extent permissible under applicable law, we do not warrant
              that specifications, pricing, or other content on the Platform is
              complete, accurate, reliable, current, or error-free. To the
              extent permissible by law, we make no warranties as to the
              availability or accessibility of the Platform, and (except as
              otherwise set out in these Terms) we will not be liable for any
              damages, loss, costs, or expenses incurred by you as a result of
              any lack of availability or accessibility of the Platform. Your
              use of the Platform may vary in functionality, availability and
              quality depending on the type of the device and the operating
              system that you use. It is your responsibility to pay for all
              costs and expenses that you may incur while using the Platform.
            </div>
            <p>
              <strong>App Permissions.</strong> By using the App, you agree that
              Company may access your device in order to allow for or optimize
              your use of the App. You consent to these activities by installing
              the App, using the App, or setting certain permissions or settings
              allowing such activities. Your device settings may enable you to
              disable, change or limit some of these activities, and you can
              disable all of them associated with the App by uninstalling the
              App.
            </p>
            <div>
              <strong>Platform Updates and Upgrades.</strong> You acknowledge
              that Company may issue updated or upgraded versions of the
              Platform and may (subject to your device settings) automatically
              electronically update or upgrade the version of the Platform that
              you are then currently using on your mobile device. You consent to
              receive updates or upgrades to the Platform automatically.
              Platform updates or upgrades may: (i) cause your device to
              automatically communicate with our servers to deliver the
              Platform’s functionality, new features, and to record usage
              metrics; (ii) affect preferences or data stored on your device;
              and (iii) collect personal information (for more information, see
              our <a href="https://podtoks.com/privacy">Privacy Policy</a>). We
              are not responsible if an update or upgrade affects how the
              Platform works if this is caused by your own equipment or device
              not supporting the update or upgrade. If you do not want App
              updates or upgrades, you can uninstall the App.
            </div>
            <p>
              <strong>Communications.</strong> By providing us with your email
              address and using the Platform, you hereby affirmatively consent
              to the use of your email address for notifications from us
              regarding important service announcements and other administrative
              communications related to your use of the Platform, as well as
              certain marketing and other advertising communications from us and
              from our third-party advertising partners, as more fully set forth
              in our
              <a href="https://podtoks.com/privacy">Privacy Policy</a>.
            </p>
            <div>
              <strong>Location-Based Features.</strong>  If location-based
              features are enabled on your device, you acknowledge that your
              device location, determined by your IP address, may be processed
              by us, and our vendors, to deliver location-specific content to
              you through the Platform. You may disable location detection
              through your browser or device settings. Your proximity or
              connection to wi-fi, Bluetooth and other networks may still be
              detected when location services are turned off on our browser or
              device settings. Territory geo- filtering maybe required in
              connection with your use of some Platform features due, for
              instance, to content territory restrictions.
            </div>
            <p>
              <strong>Feedback.</strong> While our own staff is continually
              working to develop and evaluate our own product ideas and
              features, we pride ourselves on paying close attention to the
              interests, feedback, comments, and suggestions we receive from the
              user community.  If you choose to contribute by sending us or our
              employees any ideas for products, services, features,
              modifications, enhancements, content, refinements, technologies,
              content offerings (such as audio, visual, games, or other types of
              content), promotions, strategies, or product/feature names, or any
              related documentation, artwork, computer code, diagrams, or other
              materials (collectively, “<strong>Feedback</strong>”), then
              regardless of what your accompanying communication may say, the
              following terms will apply, so that future misunderstandings can
              be avoided.  Accordingly, by sending us Feedback, you agree that:
              (i) Company has no obligation to review, consider, or implement
              your Feedback, or to return to you all or part of any Feedback for
              any reason; (ii) Feedback is provided on a non-confidential basis,
              and Company is not under any obligation to keep any Feedback you
              send confidential or to refrain from using or disclosing it in any
              way; and (iii) you irrevocably grant Company a perpetual and
              unlimited permission to reproduce, distribute, create derivative
              works of, modify, publicly perform (including on a
              through-to-the-audience basis), communicate to the public, make
              available, publicly display, and otherwise use and exploit the
              Feedback and derivatives thereof for any purpose and without
              restriction, free of charge and without attribution of any kind,
              including by making, using, selling, offering for sale, importing,
              and promoting commercial products and services that incorporate or
              embody Feedback, whether in whole or in part, and whether as
              provided or as modified.
            </p>
            <div>
              <strong>Business Transactions.</strong> We reserve the right to
              sell or transfer any information or content, including UGC, we
              obtain through the Platform in connection with any joint venture,
              partnership, merger, or other collaboration with another
              organization, or in the event we sell or transfer all or a portion
              of our business or assets (e.g., further to a reorganization,
              liquidation, or any other business transaction, including
              negotiations of such transactions).
            </div>
            <p>
              <strong>Governing Law.</strong> These Terms are governed by and
              construed in accordance with the internal laws of the State of
              California, without reference to principles of conflicts of laws,
              and any action arising out of or relating to these Terms shall be
              filed only in the state or federal courts located in Los Angeles
              County, California and you hereby consent and submit to the
              exclusive personal jurisdiction and venue of such courts.
            </p>
            <div>
              <strong>Severability.</strong> If any provision of these Terms is
              held by any competent authority to be invalid or unenforceable in
              whole or in part, the validity of the other provisions (and the
              remainder of the provision in question) shall be unaffected.
            </div>
            <p>
              <strong>Entire Agreement.</strong> Except for any Additional Terms
              that apply to your use of the Platform as we may notify or make
              available to you, this is the entire understanding between you and
              us regarding the use of the Platform, and supersedes all prior and
              contemporaneous agreements and understandings between you and us
              regarding this subject matter.
            </p>
            <div>
              <strong>Assignment.</strong> These Terms and all of your rights
              and obligations under the Terms are not assignable or transferable
              by you without our prior written consent. We may freely assign,
              transfer, or delegate these Terms or any of our rights and
              obligations under them.
            </div>
            <p>
              <strong>Termination or Changes.</strong> To the extent permitted
              by law, we reserve the right to terminate your access to and use
              of the Platform and any of its features in our reasonable
              discretion, without notice and without liability, including,
              without limitation, if we believe your conduct fails to conform to
              these Terms or any Additional Terms that may be provided to you or
              agreed upon between you and us. We also reserve the exclusive
              right to modify, withdraw, suspend or discontinue, temporarily or
              permanently, at any time and from time to time, any Materials,
              information or content available on the Platform, without
              limitation, in whole or in part, including the cessation of all
              activities associated with the Platform, with or without notice.
              You agree that we will not be liable to you or to any other party
              for any modification, suspension or discontinuance of the Platform
              or any part thereof. We also reserve the right to charge for use
              of the Platform, in whole or in part, and to change our fees for
              use of the Platform from time to time in our reasonable discretion
              and upon reasonable prior notice.
            </p>
            <div>
              <strong>Our Affiliates, Suppliers and Licensors.</strong> Our
              affiliates, suppliers, licensors, and other Company Parties are
              intended beneficiaries of these Terms.
            </div>
            <p>
              <strong>No Waiver.</strong> Our failure or delay to exercise or
              enforce any right, remedy, or provision of these Terms or any
              other legal right will not operate as a waiver of such or any
              other right, remedy, or provision.
            </p>
            <div>
              <strong>Export Controls.</strong>  Software related to or made
              available by Company may be subject to export controls of the
              U.S.A. that prohibit software from Company from being downloaded,
              exported, or re-exported (i) into (or to a national or resident
              of) any country or other jurisdiction to which the U.S.A. has
              embargoed (e.g. Cuba, North Korea, Iran, Sudan, and Syria), or
              (ii) to anyone on the U.S. Treasury Department’s list of Specially
              Designated Nationals or the U.S. Commerce Department’s Table of
              Deny Orders, or (iii) to anyone on the U.S. Department of
              Commerce’s Bureau of Industry and Security Entities List as
              published in the Export Administration Regulations.  You are
              responsible for complying with all applicable trade regulations
              and laws both foreign and domestic.  Except as authorized by U.S
              law, you agree and warrant not to export or re- export the
              software to any country, or to any person, entity, or end-user
              subject to U.S. export controls or sanctions, including, without
              limitation, as set forth in subparts (i) – (iii) above.
            </div>
            <p>
              <strong>Investigations; Cooperation with Law Enforcement.</strong>
               Company reserves the right to investigate and prosecute any
              suspected or actual violations of these Terms. Company may
              disclose any information as necessary or appropriate to satisfy
              any law, regulation, legal process, or government request.
            </p>
            <div>
              <strong>Electronic Contracting.</strong> You agree that all
              agreements, notices, disclosures, and other communications that we
              provide to you electronically satisfy any legal requirement that
              such communications be in writing. You agree that any time you
              electronically transact, agree, or consent via the Platform it is
              intended to be an electronic signature which binds you as if you
              had signed on paper. You agree that your use of the Platform
              constitutes agreement to these Terms, and any applicable
              Additional Terms, then posted without further action by you.
            </div>
            <p>
              <strong>California Notices.</strong>  Residents of California are
              entitled to the following specific consumer rights information:
              you may contact the Complaint Assistance Unit of the Division of
              Consumer Services of the Department of Consumer Affairs by mail
              at: 1625 North Market Blvd., Suite N 112, Sacramento, California,
              95834, or by telephone at (916) 445-1254.  Hearing-impaired users
              can reach the Complaint Assistance Unit at TDD (800) 326-2297 or
              TDD (916) 322-1700.  Their website is located at: {" "}
              <a href="https://www.dca.ca.gov/" target="_blank"></a>{" "}
              http://www.dca.ca.gov.
            </p>
            <div>
              Force Majeure. We will not be liable to you for failing to perform
              our obligations under or arising out of these Terms or any
              applicable laws or regulations because of any event beyond our
              reasonable control.
            </div>
            <h3>ADDITIONAL LICENSE TERMS</h3>
            <div>
              <strong>Terms Applicable For Apple IOS.</strong> If you are
              accessing or using the App through Apple IOS Apple App Store, the
              following applicable terms and conditions are applicable to you:
            </div>
            <p>
              (a) You acknowledge that these Terms are entered into between you
              and Company and, that Apple, Inc. (“Apple”) is not a party to
              these Terms other than as third-party beneficiary as contemplated
              below.
            </p>
            <div>
              (b) Any licenses granted to you by these Terms is subject to the
              permitted Usage Rules set forth in the App Store Terms of Service
              (see: 
              <a href="https://www.apple.com/legal/internet-services/itunes/us/terms.html">
                http://www.apple.com/legal/itunes/us/terms.html
              </a>
              ) and any third party terms of agreement applicable to the App.
            </div>
            <p>
              (c) You acknowledge that Company, and not Apple, is responsible
              for providing the App and Materials thereof.
            </p>
            <div>
              (d) You acknowledge that Apple has no obligation whatsoever to
              furnish any maintenance or any support services to you with
              respect to the App.
            </div>
            <p>
              (e) To the maximum extent permitted by applicable law, Apple will
              have no other warranty obligation whatsoever with respect to the
              App.
            </p>
            <div>
              (f) Notwithstanding anything to the contrary herein, and subject
              to the terms in these Terms, you acknowledge that, solely as
              between Apple and Company, Company, and not Apple is responsible
              for addressing any claims you may have relating to the App, or
              your possession and/or use thereof, including, but not limited,
              to: (i) product liability claims; (ii) any claim that the App
              fails to confirm to any applicable legal or regulatory
              requirement; and (iii) claims arising under consumer protection or
              similar legislation.
            </div>
            <p>
              (g) Further, you agree that if the App, or your possession and use
              of the App, infringes on a third party’s intellectual property
              rights, you will not hold Apple responsible for the investigation,
              defense, settlement and discharge of any such intellectual
              property infringement claims.
            </p>
            <div>
              (h) You acknowledge and agree that Apple, and Apple’s
              subsidiaries, are third-party beneficiaries of these Terms, and
              that, upon your acceptance of the terms and conditions of these
              Terms, Apple will have the right (and will be deemed to have
              accepted the right) to enforce these Terms against you as a
              third-party beneficiary thereof.
            </div>
            <p>
              (i) When using the App, you agree to comply with any and all
              third-party terms that are applicable to any platform, website,
              technology or service that interacts with the App.
            </p>
            <div>
              (j) Your use of real time route guidance on the App is at your
              sole risk. Location data may not be accurate.
            </div>
            <p>
              <strong>Terms Applicable For Google Play.</strong> If the App is
              provided to you through Google Inc. (Google Inc. together with all
              of its affiliates, “Google”) Google Play, the following terms and
              conditions apply to you in addition to all the other terms and
              conditions of these Terms:
            </p>
            <div>
              (a) You acknowledge that Google is not responsible for providing
              support services for the App.
            </div>
            <p>
              (b) If any of the terms and conditions in these Terms are
              inconsistent or in conflict with the Google Play Developer
              Distribution Agreement (the current version as of the date these
              Terms of Use was last updated is located at{" "}
              <a href="https://play.google.com/about/developer-distribution-agreement.html">
                https://play.google.com/about/developer-distribution-agreement.html
              </a>
              ), the terms and conditions of Google’s Google Play Developer
              Distribution Agreement will apply to the extent of such
              inconsistency or conflict.
            </p>
            <h3>CONTACT</h3>
            <div>
              You may contact us at PodToks Inc at{" "}
              <a href="mailto:support@podtoks.com">support@podtoks.com</a>
            </div>



            

          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
