import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "../component/Footer";
import Body from "./Body";
import Navbar from "../component/Navbar";
import Privacynew from "../Privacynew";

const Privacy = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts (page loads)
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <Body />
      <Footer />
    </div>
  );
};

export default Privacy;
