// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profit-main {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 6vw;
}

.profit-second {
    width: 80%;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 20px;
}
.teamup-box{
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.teamup-box-one{
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}
.teamup-box-inside{
    display: flex;
    gap: 30px;
}
@media (max-width: 800px) {
    .profit-second{
        width: 80%;
        grid-template-columns: repeat(1,1fr);
    }
    .teamup-box-inside{
        flex-direction: column;
    }
    .teamup-box{
        justify-content: flex-start;
        align-items: flex-start;
    }

}`, "",{"version":3,"sources":["webpack://./src/Business/Teamup.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,oCAAoC;IACpC,SAAS;AACb;AACA;IACI,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,uBAAuB;IACvB,sBAAsB;AAC1B;AACA;IACI,aAAa;IACb,SAAS;AACb;AACA;IACI;QACI,UAAU;QACV,oCAAoC;IACxC;IACA;QACI,sBAAsB;IAC1B;IACA;QACI,2BAA2B;QAC3B,uBAAuB;IAC3B;;AAEJ","sourcesContent":[".profit-main {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    margin-top: 6vw;\n}\n\n.profit-second {\n    width: 80%;\n    display: grid;\n    grid-template-columns: repeat(2,1fr);\n    gap: 20px;\n}\n.teamup-box{\n    background-color: white;\n    padding: 20px;\n    border-radius: 10px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n.teamup-box-one{\n    padding: 20px;\n    display: flex;\n    justify-content: center;\n    align-items: flex-start;\n    flex-direction: column;\n}\n.teamup-box-inside{\n    display: flex;\n    gap: 30px;\n}\n@media (max-width: 800px) {\n    .profit-second{\n        width: 80%;\n        grid-template-columns: repeat(1,1fr);\n    }\n    .teamup-box-inside{\n        flex-direction: column;\n    }\n    .teamup-box{\n        justify-content: flex-start;\n        align-items: flex-start;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
