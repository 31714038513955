import React, { useEffect } from "react";
import Navbar from "../component/Navbar";
import Body from "./Body";
import Footer from "../component/Footer";

const Community = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts (page loads)
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />
      <Body />
      <Footer />
    </div>
  );
};

export default Community;
