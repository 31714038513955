import React, { useEffect } from "react";
import Header from "./Header";
import Mission from "./Mission";
import Creator from "./Creator";
import Footer from "../component/Footer";
import Navbar from "../component/Navbar";

const About = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts (page loads)
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ background: "white" }}>
      <Navbar />
      <Header />
      <Mission />
      <Creator />
      <Footer />
    </div>
  );
};

export default About;
