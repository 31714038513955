// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.usage-main{
    /* margin-top: 5em; */
    margin-bottom: 4.5em;
}
.usage-inside{
    width: 80%;
    margin: 0 auto;
}
.usage-inside h2{
    text-align: left;
    margin-bottom: 0.5em;
    font-size: 42px;
    letter-spacing: 1px;
    font-weight: 500;
}
.list-usage-section{
    display: grid;
    grid-template-columns: 365px 680px;
    grid-gap: 20px;
    margin-top: 2em;
    justify-content: center;
}
.hash-item{
    color: black;
    background: unset;
    padding: 10px;
    border-radius: 10px;
    margin: 10px 0;
    transition: all 0.5s linear;
    list-style: none;
    position: relative;
}
.hash-p{
    font-size: 14px;
    font-weight: 300;
    margin: 10px 0;
    font-family: 'Poppins',sans-serif;
    display: none;
    color: black;
    position: absolute;
    height: max-content;
}
.hash-item:hover{
    background: white;
    height: 100px;
}
.hash-item:hover .hash-p{
    margin-top: -15px;
    display: block;
    height: max-content;
    padding-left: 20px;
}
@media (max-width:768px) {
    .list-usage-section{
        grid-template-columns: 500px;
    }
}
@media (max-width:490px) {
    .list-usage-section{
        grid-template-columns: 300px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/css/Usage.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,oBAAoB;AACxB;AACA;IACI,UAAU;IACV,cAAc;AAClB;AACA;IACI,gBAAgB;IAChB,oBAAoB;IACpB,eAAe;IACf,mBAAmB;IACnB,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,kCAAkC;IAClC,cAAc;IACd,eAAe;IACf,uBAAuB;AAC3B;AACA;IACI,YAAY;IACZ,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,cAAc;IACd,2BAA2B;IAC3B,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,iCAAiC;IACjC,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;AACvB;AACA;IACI,iBAAiB;IACjB,aAAa;AACjB;AACA;IACI,iBAAiB;IACjB,cAAc;IACd,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI;QACI,4BAA4B;IAChC;AACJ;AACA;IACI;QACI,4BAA4B;IAChC;AACJ","sourcesContent":[".usage-main{\n    /* margin-top: 5em; */\n    margin-bottom: 4.5em;\n}\n.usage-inside{\n    width: 80%;\n    margin: 0 auto;\n}\n.usage-inside h2{\n    text-align: left;\n    margin-bottom: 0.5em;\n    font-size: 42px;\n    letter-spacing: 1px;\n    font-weight: 500;\n}\n.list-usage-section{\n    display: grid;\n    grid-template-columns: 365px 680px;\n    grid-gap: 20px;\n    margin-top: 2em;\n    justify-content: center;\n}\n.hash-item{\n    color: black;\n    background: unset;\n    padding: 10px;\n    border-radius: 10px;\n    margin: 10px 0;\n    transition: all 0.5s linear;\n    list-style: none;\n    position: relative;\n}\n.hash-p{\n    font-size: 14px;\n    font-weight: 300;\n    margin: 10px 0;\n    font-family: 'Poppins',sans-serif;\n    display: none;\n    color: black;\n    position: absolute;\n    height: max-content;\n}\n.hash-item:hover{\n    background: white;\n    height: 100px;\n}\n.hash-item:hover .hash-p{\n    margin-top: -15px;\n    display: block;\n    height: max-content;\n    padding-left: 20px;\n}\n@media (max-width:768px) {\n    .list-usage-section{\n        grid-template-columns: 500px;\n    }\n}\n@media (max-width:490px) {\n    .list-usage-section{\n        grid-template-columns: 300px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
