import Download from "./component/Download";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Feature from "./component/Feature";
import Footer from "./component/Footer";
import Imagesection from "./component/Imagesection";
import Navbar from "./component/Navbar";
import Review from "./component/Review";
import Usage from "./component/Usage";
import Video from "./component/Video";
import What from "./component/What";
import Work from "./component/Work";
import Home from "./component/Home";
import About from "./About/About";
import Business from "./Business/Business";
import Privacy from "./Privacy/Privacy";
import "react-tooltip/dist/react-tooltip.css";
import Terms from "./Terms/Terms";
import Community from "./Community/Community";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/Aboutus" element={<About />} />
          <Route path="/Business" element={<Business />} /> */}
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/termsofservice" element={<Terms />} />
          <Route path="/communityguidelines" element={<Community />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
