import React, { useEffect } from "react";
import Imagesection from "./Imagesection";
import What from "./What";
import Work from "./Work";
import Video from "./Video";
import Usage from "./Usage";
import Review from "./Review";
import Download from "./Download";
import Footer from "./Footer";
import Navbar from "./Navbar";
import Backtotop from "./Backtotop";

const Home = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts (page loads)
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ background: "white" }}>
      <Navbar />
      <Imagesection />
      {/* <Feature /> */}
      <What />
      <Work />
      <Video />
      <Usage />
      <Review />
      <Download />
      <Footer />
    </div>
  );
};

export default Home;
