import React, { useState, useEffect } from "react";
import "../css/Navbar.css";
import { Link } from "react-router-dom";
import Image from "../assets/podtokpink2_in_white-removebg-preview.png";
import { Tooltip as ReactTooltip } from "react-tooltip";

const Navbar = () => {
  const [scrolling, setScrolling] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleMenuToggle = () => {
    setShowMenu(!showMenu);
  };

  return (
    <nav className={`navbar ${scrolling ? "scrolling" : ""}`}>
      <div className="navbar-logo">
        <a href="/">
          <img src={Image} alt="" width={100} height={60} />
        </a>
      </div>
      <div className={`navbar-list ${showMenu ? "show" : ""}`}>
        <ul>
          <li>
            <a href="#section1" id="business">
              I'm a Business
            </a>

            <ReactTooltip
              anchorId="business"
              place="top"
              content="Coming Soon"
            />
          </li>
          <li>
            <a href="#section2" id="Support">
              I'm a Creator
            </a>
            <ReactTooltip
              anchorId="Support"
              place="top"
              content="Coming Soon"
            />
          </li>
          <li>
            <a href="#section3" id="About">
              About
            </a>

            <ReactTooltip anchorId="About" place="top" content="Coming Soon" />
          </li>
        </ul>
      </div>
      <div
        className={`navbar-button ${scrolling ? "hide" : ""}`}
        style={{ marginRight: "100px" }}
      >
        <button style={{ color: "white" }}>
          <a
            href="#Download"
            style={{
              color: "white",
              textDecoration: "none",
              fontWeight: "bold",
            }}
          >
            Try PodToks
          </a>
        </button>
      </div>
      <div className="navbar-hamburger" onClick={handleMenuToggle}>
        <div className={`line ${showMenu ? "open" : ""}`}></div>
        <div className={`line ${showMenu ? "open" : ""}`}></div>
        <div className={`line ${showMenu ? "open" : ""}`}></div>
      </div>
    </nav>
  );
};

export default Navbar;
