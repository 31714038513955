import React from "react";
import ImageF from "../assets/PodToks_Testimonial_Image1.jpg";
import ImageS from "../assets/PodToks_Testimonial_Image2.jpg";
import ImageT from "../assets/PodToks_Testimonial_Image3.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

const Review = () => {
  return (
    <div className="review-container" style={{ marginTop: "-110px" }}>
      <div className="review-slider">
        <h2 className="review-h2">What our clients have to say</h2>
        <div className="review-div">
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            slidesPerView={1}
            //   navigation
            autoplay={true}
            //   pagination={{ clickable: true }}
          >
            <SwiperSlide>
              <div>
                {/* image */}
                <div className="review-image">
                  <img
                    src={ImageF}
                    alt=""
                    width="100%"
                    style={{ borderRadius: "50%" }}
                  />
                </div>
                {/* text */}
                <p className="review-p">
                  “This app has been an incredible platform for sharing my
                  music. The algorithm really helps in getting my content
                  discovered by new listeners. I've gained a substantial
                  following, and it's thrilling to see my music reach people
                  across the globe. It's the perfect place for artists looking
                  to expand their audience.”
                </p>
                {/* review */}
                <div className="review-star">
                  <img
                    src="https://zukaz.com/assets/img/Group_65.png"
                    alt=""
                    width={110}
                    height={17}
                  />
                </div>
                {/* name */}
                <h4 className="review-inside-h4">- Sam Atswell</h4>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                {/* image */}
                <div className="review-image">
                  <img
                    src={ImageS}
                    alt=""
                    width="100%"
                    style={{ borderRadius: "50%" }}
                  />
                </div>
                {/* text */}
                <p className="review-p">
                  “I've traveled to some amazing places and always struggled to
                  share my experiences widely. This app changed that. Its
                  discoverability feature is top-notch, connecting my travel
                  vlogs with enthusiasts who genuinely care about adventure and
                  exploration. I've received more engagement here in a few
                  months than I did in years on other platforms”
                </p>
                {/* review */}
                <div className="review-star">
                  <img
                    src="https://zukaz.com/assets/img/Group_65.png"
                    alt=""
                    width={110}
                    height={17}
                  />
                </div>
                {/* name */}
                <h4 className="review-inside-h4">- Jim Strukzucki</h4>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                {/* image */}
                <div className="review-image">
                  <img
                    src={ImageT}
                    alt=""
                    width="100%"
                    style={{ borderRadius: "50%" }}
                  />
                </div>
                {/* text */}
                <p className="review-p">
                  “I create DIY craft tutorials, and this app has been a
                  blessing for reaching a wider audience. The way it promotes my
                  videos to people interested in crafts is phenomenal. I've not
                  only gained more followers but also connected with a community
                  that's passionate about crafting. It's amazing to see my
                  content getting the recognition it deserves.”
                </p>
                {/* review */}
                <div className="review-star">
                  <img
                    src="https://zukaz.com/assets/img/Group_65.png"
                    alt=""
                    width={110}
                    height={17}
                  />
                </div>
                {/* name */}
                <h4 className="review-inside-h4">- Solan Hangrove</h4>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Review;
