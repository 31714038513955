// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-main{
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
}
.profit-second-privacy{
    width: 80%;
}
.privacy-first{
    width: 30%;
    border: 1px solid #ccc;
    border-radius: 10px 0 0 0;
    padding: 3em 1em 0 1.5em;
    background: white;
}
.privacy-second{
    width: 70%;
    background: #fafafa;
    padding: 3em 6.5em 4em 6.5em;
    border: 1px solid #ccc;
    border-radius: 0 10px 0 0;
    border-left: none;
}
.privacy-box li{
     margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Privacy/Privacy.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,qBAAqB;AACzB;AACA;IACI,UAAU;AACd;AACA;IACI,UAAU;IACV,sBAAsB;IACtB,yBAAyB;IACzB,wBAAwB;IACxB,iBAAiB;AACrB;AACA;IACI,UAAU;IACV,mBAAmB;IACnB,4BAA4B;IAC5B,sBAAsB;IACtB,yBAAyB;IACzB,iBAAiB;AACrB;AACA;KACK,gBAAgB;AACrB","sourcesContent":[".privacy-main{\n    display: flex;\n    flex-direction: row;\n    /* flex-wrap: wrap; */\n}\n.profit-second-privacy{\n    width: 80%;\n}\n.privacy-first{\n    width: 30%;\n    border: 1px solid #ccc;\n    border-radius: 10px 0 0 0;\n    padding: 3em 1em 0 1.5em;\n    background: white;\n}\n.privacy-second{\n    width: 70%;\n    background: #fafafa;\n    padding: 3em 6.5em 4em 6.5em;\n    border: 1px solid #ccc;\n    border-radius: 0 10px 0 0;\n    border-left: none;\n}\n.privacy-box li{\n     margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
