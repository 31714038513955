import React, { useEffect } from "react";
import ImageSection from "./ImageSection";
import Feature from "../component/Feature";
import Didknow from "./Didknow";
import Video from "./Video";
import Teamup from "./Teamup";
import Started from "./Started";
import Review from "./Review";
import Customer from "./Customer";
import Footer from "./Footer";
import Navbar from "./Navbar";

const Business = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts (page loads)
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ background: "#e6faff" }}>
      <Navbar />
      <ImageSection />
      <Feature />
      <Didknow />
      <Video />
      <Teamup />
      <Started />
      <Review />
      <Customer />
      <Footer />
    </div>
  );
};

export default Business;
