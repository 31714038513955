import React from "react";

const Didknow = () => {
  return (
    <div className="what-container">
      <div className="inside-what">
        <div className="inside-div">
          {/* what-left */}
          <div className="what-left">
            <h2>Did you know?</h2>
            <p>
              With Zukaz, you can expect customer conversion rates of up to
              <strong style={{ fontWeight: "bold", marginLeft: "5px" }}>
                48.4%!
              </strong>
            </p>
            <p>Don’t settle for less. Partner with us today!</p>
            <button
              style={{
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "60px",
                paddingRight: "60px",
                border: "none",
                color: "white",
                fontSize: "20px",
                borderRadius: "30px",
                marginTop: "20px",
                background: "#008da6",
              }}
            >
              Partner with us
            </button>
          </div>
          {/* what-right */}
          <div className="what-right">
            <img
              src="https://zukaz.com/assets/img/Zukaz-Merchants-by-Moworks-crowd.png"
              alt=""
              width="100%"
              height={436}
              className="what-image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Didknow;
