// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-detail-about {
    width: 75%;
    right: -20em;
    display: block;
    top: -50px;
    position: absolute;
}
@media (max-width:768px) {
    
    
    .image-detail-about {
        right: 0;
        width: 48rem;
        max-width: 48rem;
    }

}`, "",{"version":3,"sources":["webpack://./src/About/Header.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,cAAc;IACd,UAAU;IACV,kBAAkB;AACtB;AACA;;;IAGI;QACI,QAAQ;QACR,YAAY;QACZ,gBAAgB;IACpB;;AAEJ","sourcesContent":[".image-detail-about {\n    width: 75%;\n    right: -20em;\n    display: block;\n    top: -50px;\n    position: absolute;\n}\n@media (max-width:768px) {\n    \n    \n    .image-detail-about {\n        right: 0;\n        width: 48rem;\n        max-width: 48rem;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
