import React from "react";
import "../css/Work.css";

const Work = () => {
  return (
    <div style={{ background: "white", width: "100%" }}>
      <div className="main-work">
        <div className="work">
          <h2
            style={{ textAlign: "center", fontSize: "50px", marginTop: "0px" }}
          >
            How It Works
          </h2>
          <div className="list-work">
            <div className="inside-work">
              <div>
                <img
                  src="https://zukaz.com/assets/img/teamup-2.png"
                  alt=""
                  width={200}
                  height={200}
                  className="work-image"
                />
              </div>
              <div className="div-work">
                <h2
                  style={{
                    marginTop: "1em",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                    textAlign: "left",
                  }}
                >
                  Discover
                </h2>
                <p className="work-p">
                  Discover new content for your focus areas and engage your
                  brain
                </p>
              </div>
            </div>
            {/* second item */}
            <div className="inside-work">
              <div>
                <img
                  src="https://zukaz.com/assets/img/teamup-5.png"
                  alt=""
                  width={200}
                  height={200}
                  className="work-image"
                />
              </div>
              <div className="div-work">
                <h2
                  style={{
                    marginTop: "1em",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                    textAlign: "left",
                  }}
                >
                  Engage
                </h2>
                <p className="work-p">
                  Interact and Engage with the content you like and show your
                  support
                </p>
              </div>
            </div>
            {/* third item */}
            <div className="inside-work">
              <div>
                <img
                  src="https://zukaz.com/assets/img/Group_230.png"
                  alt=""
                  width={200}
                  height={200}
                  className="work-image"
                />
              </div>
              <div className="div-work">
                <h2
                  style={{
                    marginTop: "1em",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                    textAlign: "left",
                  }}
                >
                  Find New Fans
                </h2>
                <p className="work-p">
                  As a creator of good content find fans who are focused on your
                  area of expertise
                </p>
              </div>
            </div>
            {/* fourth item */}
            <div className="inside-work">
              <div>
                <img
                  src="https://zukaz.com/assets/img/explore.png"
                  alt=""
                  width={200}
                  height={200}
                  className="work-image"
                />
              </div>
              <div className="div-work">
                <h2
                  style={{
                    marginTop: "1em",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                    textAlign: "left",
                  }}
                >
                  Supercharge your mission
                </h2>
                <p className="work-p">
                  Find supporters, fans and monetize and supercharge your
                  mission
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
