import React, { useEffect, useState } from "react";

const Backtotop = () => {
  const [showChatbot, setShowChatbot] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Adjust the offset and condition based on your design
      const offset = 100;
      const currentScrollPos = window.scrollY;

      if (currentScrollPos > offset) {
        setShowChatbot(true);
      } else {
        setShowChatbot(false);
      }
    };

    //Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div>
      {showChatbot && (
        <button
          style={{
            position: "fixed",
            bottom: "50px",
            right: "50px",
            height: "50px",
            width: "50px",
            fontSize: "60px",
            zIndex: "10000",
            borderRadius: "50%",
            border: "none",
            background: "#792dd7",
            cursor: "pointer",
            color: "white",
          }}
          onClick={scrollUp}
        >
          ^
        </button>
      )}
    </div>
  );
};

export default Backtotop;
