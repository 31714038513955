// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customer-main{
    background-image: url('https://zukaz.com/assets/img/map.png');
    background-position: right;
    background-size: 60% 176%;
    background-repeat: no-repeat;
    position: relative;
    margin-bottom: 4em;
}
.image-first-customer{
    width: 111%;
    position: absolute;
    bottom: 20em;
    left: -1em;
}
.image-second-customer{
    width: 113%;
    height: 332px;
    margin-bottom: -5px;
}
.image-third-customer{
    width: 19%;
    position: absolute;
    top: -5em;
    right: 7em;
}
.animation-customer{
    top: 4em;
    position: absolute;
    left: 12em;
}`, "",{"version":3,"sources":["webpack://./src/Business/Customer.css"],"names":[],"mappings":"AAAA;IACI,6DAA6D;IAC7D,0BAA0B;IAC1B,yBAAyB;IACzB,4BAA4B;IAC5B,kBAAkB;IAClB,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,UAAU;AACd;AACA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,UAAU;IACV,kBAAkB;IAClB,SAAS;IACT,UAAU;AACd;AACA;IACI,QAAQ;IACR,kBAAkB;IAClB,UAAU;AACd","sourcesContent":[".customer-main{\n    background-image: url('https://zukaz.com/assets/img/map.png');\n    background-position: right;\n    background-size: 60% 176%;\n    background-repeat: no-repeat;\n    position: relative;\n    margin-bottom: 4em;\n}\n.image-first-customer{\n    width: 111%;\n    position: absolute;\n    bottom: 20em;\n    left: -1em;\n}\n.image-second-customer{\n    width: 113%;\n    height: 332px;\n    margin-bottom: -5px;\n}\n.image-third-customer{\n    width: 19%;\n    position: absolute;\n    top: -5em;\n    right: 7em;\n}\n.animation-customer{\n    top: 4em;\n    position: absolute;\n    left: 12em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
