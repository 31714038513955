// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-detail {
    width: 75%;
    right: -20em;
    display: block;
    top: -130px;
    position: absolute;
}

.header-banner {
    width: 100%;
    /* margin-top: 6em; */
    display: flex;
    position: relative;
    z-index: 100;
    padding-top: 10em;
    margin-left: 100px;
}

.banner-left {
    width: 50%;
    margin-top: -3em;
}

h1 {
    margin-bottom: 5px;
    padding-bottom: 14px;
    font-weight: 600;
    font-size: 6vw;
}

span {
    font-size: 2vw;
}

.banner-right {
    width: 50%;
}

.phone-hand {
    width: 45%;
    position: relative;
    margin-top: -1em;
}
.image-detail-inside{
      position: relative;
      width: 100%;
      top: -130px;
      left: 0em;   
}
@media (max-width:768px) {
    .header-banner{
        margin-left: 0;
    }
    .banner-left{
        margin-left: 3rem;
    }
    .image-detail-inside{
        top: -200px;
    }
    .image-detail{
        right: 0;
        width: 48rem;
        max-width: 48rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/css/Image.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,cAAc;IACd,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,qBAAqB;IACrB,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,gBAAgB;AACpB;AACA;MACM,kBAAkB;MAClB,WAAW;MACX,WAAW;MACX,SAAS;AACf;AACA;IACI;QACI,cAAc;IAClB;IACA;QACI,iBAAiB;IACrB;IACA;QACI,WAAW;IACf;IACA;QACI,QAAQ;QACR,YAAY;QACZ,gBAAgB;IACpB;AACJ","sourcesContent":[".image-detail {\n    width: 75%;\n    right: -20em;\n    display: block;\n    top: -130px;\n    position: absolute;\n}\n\n.header-banner {\n    width: 100%;\n    /* margin-top: 6em; */\n    display: flex;\n    position: relative;\n    z-index: 100;\n    padding-top: 10em;\n    margin-left: 100px;\n}\n\n.banner-left {\n    width: 50%;\n    margin-top: -3em;\n}\n\nh1 {\n    margin-bottom: 5px;\n    padding-bottom: 14px;\n    font-weight: 600;\n    font-size: 6vw;\n}\n\nspan {\n    font-size: 2vw;\n}\n\n.banner-right {\n    width: 50%;\n}\n\n.phone-hand {\n    width: 45%;\n    position: relative;\n    margin-top: -1em;\n}\n.image-detail-inside{\n      position: relative;\n      width: 100%;\n      top: -130px;\n      left: 0em;   \n}\n@media (max-width:768px) {\n    .header-banner{\n        margin-left: 0;\n    }\n    .banner-left{\n        margin-left: 3rem;\n    }\n    .image-detail-inside{\n        top: -200px;\n    }\n    .image-detail{\n        right: 0;\n        width: 48rem;\n        max-width: 48rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
