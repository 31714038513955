import React from "react";
import Image from "../assets/zukaz_hero-02.png";

const ImageSection = () => {
  return (
    <div>
      <div className="image-detail">
        <img src={Image} alt="image" className="image-detail-inside" />
      </div>

      <div className="header-banner">
        <div className="banner-left">
          <h1 style={{ fontSize: "70px" }}>
            Harness the power <br /> of AR with Zukaz
          </h1>
          <ul style={{ listStyle: "none", marginLeft: "-30px" }}>
            <li>
              <a href="" style={{ textDecoration: "none", cursor: "pointer" }}>
                <h4>
                  <img
                    src="https://zukaz.com/assets/img/Group_5071.png"
                    alt=""
                    width={15}
                    height={18}
                  />
                  <span
                    style={{
                      fontSize: "20px",
                      fontWeight: "500",
                      color: "black",
                      fontFamily: "Poppins",
                      paddingLeft: "10px",
                    }}
                  >
                    Reach new customers
                  </span>
                </h4>
              </a>
            </li>
            <li>
              <a href="" style={{ textDecoration: "none", cursor: "pointer" }}>
                <h4>
                  <img
                    src="https://zukaz.com/assets/img/Group_5071.png"
                    alt=""
                    width={15}
                    height={18}
                  />
                  <span
                    style={{
                      fontSize: "20px",
                      fontWeight: "500",
                      color: "black",
                      fontFamily: "Poppins",
                      paddingLeft: "10px",
                    }}
                  >
                    Increase sales
                  </span>
                </h4>
              </a>
            </li>
            <li>
              <a href="" style={{ textDecoration: "none", cursor: "pointer" }}>
                <h4>
                  <img
                    src="https://zukaz.com/assets/img/Group_5071.png"
                    alt=""
                    width={15}
                    height={18}
                  />
                  <span
                    style={{
                      fontSize: "20px",
                      fontWeight: "500",
                      color: "black",
                      fontFamily: "Poppins",
                      paddingLeft: "10px",
                    }}
                  >
                    Generate 100% marketing return
                  </span>
                </h4>
              </a>
            </li>
            <p style={{ fontSize: "12px", marginTop: "-10px" }}>
              (No upfront cost until success)
            </p>
            <button
              style={{
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "60px",
                paddingRight: "60px",
                border: "none",
                color: "white",
                fontSize: "20px",
                borderRadius: "30px",
                marginTop: "20px",
                background: "#008da6",
              }}
            >
              Partner with us
            </button>
          </ul>
        </div>

        <div className="banner-right">
          <div className="phone-hand">
            <img
              src="https://zukaz.com/assets/img/hand.png"
              alt="podtoks"
              title="podtoks"
              style={{
                width: "100%",
                position: "relative",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageSection;
