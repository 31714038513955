import React from "react";
import "../css/Footer.css";
import { Link } from "react-router-dom";
import Image from "../assets/podtokpink2_in_white-removebg-preview.png";
import { Tooltip as ReactTooltip } from "react-tooltip";

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-inside">
        <div className="footer-first">
          <div className="footer-info">
            <div className="footer-info-logo">
              <img src={Image} alt="" width={150} height={100} />
            </div>
            <div className="footer-info-detail">
              <div className="info-detail-first">
                {/* <div
                  style={{
                    fontWeight: "500",
                    marginBottom: "20px",
                    marginTop: "10px",
                  }}
                >
                  <Link
                    to="/Aboutus"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    About
                  </Link>
                </div> */}
                <div className="info-detail-div" style={{ color: "black" }}>
                  <Link
                    to="/termsofservice"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Terms Of Service
                  </Link>
                </div>
                <div className="info-detail-div" style={{ color: "black" }}>
                  <Link
                    to="/communityguidelines"
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    Community Guidelines
                  </Link>
                </div>
                <div className="info-detail-div">
                  <Link
                    to="/privacy"
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    Privacy Policy{" "}
                  </Link>
                </div>
              </div>
              {/* second */}
              <div className="info-detail-first">
                {/* <div style={{ fontWeight: "500", marginBottom: "20px" }}>
                  About
                </div> */}
                <div
                  className="info-detail-div"
                  style={{ color: "black" }}
                  id="about"
                >
                  About
                  <ReactTooltip
                    anchorId="about"
                    place="top"
                    content="Coming Soon"
                  />
                </div>
                <div
                  className="info-detail-div"
                  style={{ color: "black" }}
                  id="busines"
                >
                  I'm a Business
                  <ReactTooltip
                    anchorId="busines"
                    place="top"
                    content="Coming Soon"
                  />
                </div>
                <div
                  className="info-detail-div"
                  style={{ color: "black" }}
                  id="creator"
                >
                  I'm a Creator
                  <ReactTooltip
                    anchorId="creator"
                    place="top"
                    content="Coming Soon"
                  />
                </div>
              </div>
              {/* third */}
              {/* <div className="info-detail-first">
                <div style={{ fontWeight: "500", marginBottom: "20px" }}>
                  About
                </div>
                <div className="info-detail-div">Company</div>
                <div className="info-detail-div">Press Kit</div>
                <div className="info-detail-div">Legal</div>
              </div> */}
              {/* four */}
              {/* <div className="info-detail-first">
                <div style={{ fontWeight: "500", marginBottom: "20px" }}>
                  About
                </div>
                <div className="info-detail-div">Company</div>
                <div className="info-detail-div">Press Kit</div>
                <div className="info-detail-div">Legal</div>
              </div> */}
            </div>
            <div className="footer-info-form" style={{ color: "black" }}>
              <div style={{ fontSize: "15px" }}>
                <strong
                  style={{
                    marginRight: "5px",
                    color: "black",
                    fontSize: "15px",
                  }}
                >
                  Stay on top of the latest happenings in PodToks,
                </strong>
                including launches and content discovery
              </div>
              <div className="footer-second-form" style={{ marginTop: "20px" }}>
                <form action="">
                  <div className="form-part-one">
                    <input
                      type="text"
                      placeholder="First Name"
                      className="input-form-name"
                    />
                    <input
                      type="text"
                      placeholder="Last Name"
                      className="input-form-name input-form-second"
                    />
                  </div>
                  <div
                    className="form-part-one"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div style={{ width: "86%" }}>
                      <input
                        type="text"
                        placeholder="Email"
                        className="input-form-name"
                      />
                    </div>
                    <div className="form-two-div">
                      <input
                        type="checkbox"
                        // style={{
                        //   position: "absolute",
                        //   opacity: "0",
                        //   cursor: "pointer",
                        //   height: "0",
                        //   width: "0",
                        // }}
                      />

                      <div className="form-two-span" style={{ color: "black" }}>
                        I agree to receive emails from PodToks
                      </div>
                      {/* <span className="checkmark"></span> */}
                    </div>
                    <button className="form-button" style={{ color: "white" }}>
                      submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* second part of footer    */}
        {/* <div className="footer-second">
          <div style={{ width: "50%" }}></div>
          <div className="footer-second-form">
            <form action="">
              <div className="form-part-one">
                <input
                  type="text"
                  placeholder="First Name"
                  className="input-form-name"
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  className="input-form-name input-form-second"
                />
              </div>
              <div className="form-part-one">
                <input
                  type="text"
                  placeholder="Email"
                  className="input-email"
                />
                <button className="form-button" style={{ color: "white" }}>
                  submit
                </button>
                <div className="form-two-div">
                  <label className="lb-checked">
                    <input
                      type="checkbox"
                      style={{
                        position: "absolute",
                        opacity: "0",
                        cursor: "pointer",
                        height: "0",
                        width: "0",
                      }}
                    />
                    <span className="form-two-span" style={{ color: "black" }}>
                      I agree to receive emails from PodToks
                    </span>
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;
