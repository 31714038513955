import React from "react";
import "../css/Usage.css";
import ImageF from "../assets/kal-visuals-DqmXihYx5UE-unsplash (1).jpg";
import ImageS from "../assets/jenny-ueberberg-B8A3_0qfNzg-unsplash.jpg";
import ImagrT from "../assets/convertkit-9i3-OAIUHdY-unsplash.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

const Usage = () => {
  return (
    <div className="usage-main" style={{ background: "white" }}>
      <div className="usage-inside">
        <h2>Why PodToks Can be your new Sharing App</h2>
        <img
          src="https://zukaz.com/assets/img/Path_205.png"
          alt=""
          width={67}
          height={3}
        />
        <div className="list-usage-section">
          {/* list-section */}
          <ul>
            <li className="hash-item">
              <a href="" style={{ textDecoration: "none", cursor: "pointer" }}>
                <h4>
                  <img
                    src="https://zukaz.com/assets/img/Group_5098.png"
                    alt=""
                    width={15}
                    height={18}
                  />
                  <span
                    style={{
                      fontSize: "20px",
                      fontWeight: "500",
                      color: "black",
                      fontFamily: "Poppins",
                      paddingLeft: "10px",
                    }}
                  >
                    Focused on your intrests
                  </span>
                </h4>
                <p className="hash-p">Our model is for you to opt in</p>
              </a>
            </li>
            {/* item-2 */}
            <li className="hash-item">
              <a href="" style={{ textDecoration: "none", cursor: "pointer" }}>
                <h4>
                  <img
                    src="https://zukaz.com/assets/img/Group_5098.png"
                    alt=""
                    width={15}
                    height={18}
                  />
                  <span
                    style={{
                      fontSize: "20px",
                      fontWeight: "500",
                      color: "black",
                      fontFamily: "Poppins",
                      paddingLeft: "10px",
                    }}
                  >
                    Discover leaders
                  </span>
                </h4>
                <p className="hash-p">
                  Instead of hearing from everyone hear from industry leaders
                </p>
              </a>
            </li>
            {/* item 3 */}
            <li className="hash-item">
              <a href="" style={{ textDecoration: "none", cursor: "pointer" }}>
                <h4>
                  <img
                    src="https://zukaz.com/assets/img/Group_5098.png"
                    alt=""
                    width={15}
                    height={18}
                  />
                  <span
                    style={{
                      fontSize: "20px",
                      fontWeight: "500",
                      color: "black",
                      fontFamily: "Poppins",
                      paddingLeft: "10px",
                    }}
                  >
                    Teach More
                  </span>
                </h4>
                <p className="hash-p">
                  Moving content from entertainment to education
                </p>
              </a>
            </li>
            {/* item 4 */}
            <li className="hash-item">
              <a href="" style={{ textDecoration: "none", cursor: "pointer" }}>
                <h4>
                  <img
                    src="https://zukaz.com/assets/img/Group_5098.png"
                    alt=""
                    width={15}
                    height={18}
                  />
                  <span
                    style={{
                      fontSize: "20px",
                      fontWeight: "500",
                      color: "black",
                      fontFamily: "Poppins",
                      paddingLeft: "10px",
                    }}
                  >
                    Find your 100 Super Fans
                  </span>
                </h4>
                <p className="hash-p">
                  Instead of Millions find 100 people who believe in you
                </p>
              </a>
            </li>
            {/* item 5 */}
            <li className="hash-item">
              <a href="" style={{ textDecoration: "none", cursor: "pointer" }}>
                <h4>
                  <img
                    src="https://zukaz.com/assets/img/Group_5098.png"
                    alt=""
                    width={15}
                    height={18}
                  />
                  <span
                    style={{
                      fontSize: "20px",
                      fontWeight: "500",
                      color: "black",
                      fontFamily: "Poppins",
                      paddingLeft: "10px",
                    }}
                  >
                    Launch your Creation
                  </span>
                </h4>
                <p className="hash-p">
                  Use this as a launching pad when announcing a new creation
                </p>
              </a>
            </li>
          </ul>
          {/* image */}
          <div style={{ marginTop: "3em" }}>
            <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              slidesPerView={1}
              //   navigation
              autoplay={true}
              //   pagination={{ clickable: true }}
            >
              <SwiperSlide>
                <img
                  src={ImageF}
                  alt=""
                  width="100%"
                  height={483}
                  style={{ borderRadius: "2em", objectFit: "cover" }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={ImageS}
                  alt=""
                  width="100%"
                  height={483}
                  style={{ borderRadius: "2em" }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={ImagrT}
                  alt=""
                  width="100%"
                  height={483}
                  style={{ borderRadius: "2em" }}
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Usage;
