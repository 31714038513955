// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-section{
    /* margin-top: 6em;
    margin-bottom: 5em; */
    /* height: 750px; */
    background-color: #CBC3E3;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.video-section h2{
    font-family: 'Poppins',sans-serif;
    text-align: center;
    font-size: 42px;
    letter-spacing: 1px;
    font-weight: 500;
    margin-bottom: 1em;
}
.back-seeyour{
    width: 85%;
    margin-bottom: 50px;
    /* background-size: 100%;
    width: 85%;
    margin: auto;
    overflow: hidden;
    position: relative;
    text-align: center;
    background-image: url("https://zukaz.com/assets/img/Video-Screen-Image_01A.jpg");
    background-position: center;
    background-repeat: no-repeat; */
}
.back-seeyour-business {
    width: 85%;
    overflow: hidden;
    position: relative;
    text-align: center;
}
.back-video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/css/Video.css"],"names":[],"mappings":"AAAA;IACI;yBACqB;IACrB,mBAAmB;IACnB,yBAAyB;IACzB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,iCAAiC;IACjC,kBAAkB;IAClB,eAAe;IACf,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,UAAU;IACV,mBAAmB;IACnB;;;;;;;;mCAQ+B;AACnC;AACA;IACI,UAAU;IACV,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".video-section{\n    /* margin-top: 6em;\n    margin-bottom: 5em; */\n    /* height: 750px; */\n    background-color: #CBC3E3;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n.video-section h2{\n    font-family: 'Poppins',sans-serif;\n    text-align: center;\n    font-size: 42px;\n    letter-spacing: 1px;\n    font-weight: 500;\n    margin-bottom: 1em;\n}\n.back-seeyour{\n    width: 85%;\n    margin-bottom: 50px;\n    /* background-size: 100%;\n    width: 85%;\n    margin: auto;\n    overflow: hidden;\n    position: relative;\n    text-align: center;\n    background-image: url(\"https://zukaz.com/assets/img/Video-Screen-Image_01A.jpg\");\n    background-position: center;\n    background-repeat: no-repeat; */\n}\n.back-seeyour-business {\n    width: 85%;\n    overflow: hidden;\n    position: relative;\n    text-align: center;\n}\n.back-video{\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
