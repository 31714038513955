import React from "react";

const Privacynew = () => {
  return (
    <div className="privacy-second">
      <h3>Privacy Policy</h3>
      <p>Privacy Policy was Last Updated on: November 20 2023 </p>
      <p>Privacy Policy was updated by the final author in Sydney Australia</p>
      <p>
        This Privacy Policy is designed to help you understand how PodToks App
        which is a part of PodToks LLC, (“PodToks,” “we,” “us,” or “our”)
        collects, uses, processes, and shares your personal information, and to
        help you understand and exercise your privacy rights.
      </p>
      <h3>1. SCOPE AND UPDATES TO THIS PRIVACY POLICY</h3>
      <div>
        This Privacy Policy applies to personal information processed by us,
        including on our websites, mobile applications, and other online or
        offline offerings. To make this Privacy Policy easier to read, our
        websites, mobile applications, and other offerings are collectively
        called the “Services.”
      </div>
      <p>
        Changes to our Privacy Policy. We may revise this Privacy Policy from
        time to time in our sole discretion. If there are any material changes
        to this Privacy Policy, we will notify you as required by applicable
        law. You understand and agree that you will be deemed to have accepted
        the updated Privacy Policy if you continue to use our Services after the
        new Privacy Policy takes effect.
      </p>
      <h3>2. PERSONAL INFORMATION WE COLLECT</h3>
      <div>
        The categories of personal information we collect depend on how you
        interact with us, our Services, and the requirements of applicable law.
        We collect information that you provide to us, information we obtain
        automatically when you use our Services, and information from other
        sources such as third-party services and organizations, as described
        below.
      </div>
      <p>
        The categories of personal information we collect depend on how you
        interact with us, our Services, and the requirements of applicable law.
        We collect information that you provide to us, information we obtain
        automatically when you use our Services, and information from other
        sources such as third-party services and organizations, as described
        below.
      </p>
      <h4>1. Personal Information You Provide to Us Directly</h4>
      <p>We may collect personal information that you provide to us.</p>
      <div>
        <strong>Account Creation.</strong>
        We may collect personal information when you create an account with us,
        such as a username and password.
      </div>
      <p>
        <strong>Your Communications with Us.</strong>
        We may collect personal information, such as email address, phone
        number, or full name when you request information about our Services,
        request customer or technical support, or otherwise communicate with us.
      </p>
      <div>
        <strong>Interactive Features.</strong>
        We and others who use our Services may collect personal information that
        you submit or make available through our interactive features (e.g.,
        uploading video on the platform, messaging and chat features, commenting
        functionalities, forums, blogs, and social media pages). Any information
        you provide using the public sharing features of the Services will be
        considered “public,” unless otherwise required by applicable law, and is
        not subject to the privacy protections referenced herein. Please
        exercise caution before revealing any information that may identify you
        in the real world to other users.
      </div>
      <h4>2. Personal Information Collected Automatically</h4>
      <p>
        We may collect personal information automatically when you use our
        Services.
      </p>
      <div>
        Automatic Collection of Personal Information. We may collect certain
        information automatically when you use our Services, such as your
        Internet protocol (IP) address, user settings, cookie identifiers,
        mobile carrier, mobile advertising and other unique identifiers, browser
        or device information, and Internet service provide. We may also
        automatically collect information regarding your use of our Services,
        such as pages that you visit before, during and after using our
        Services, videos you like and share, information about the links you
        click, the types of content you interact with, the frequency and
        duration of your activities, and other information about how you use our
        Services.
      </div>
      <p>
        Crash Reports. If you provide crash reports, we may collect personal
        information related to such crash reports, including detailed diagnostic
        information about your device and the activities that led to the crash.
      </p>
      <div>
        Cookie Policy (and Other Technologies). We, as well as third parties
        that provide content, or other functionality on our Services, may use
        cookies, pixel tags, and other technologies (“Technologies”) to
        automatically collect information through your use of our Services.
      </div>
      <p>
        Cookies. Cookies are small text files placed in device browsers that
        store preferences and facilitate and enhance your experience.
      </p>
      <div>
        Pixel Tags/Web Beacons. A pixel tag (also known as a web beacon) is a
        piece of code embedded in our Services that collects information about
        engagement on our Services. The use of a pixel tag allows us to record,
        for example, that a user has visited a particular web page or clicked on
        a particular advertisement. We may also include web beacons in e-mails
        to understand whether messages have been opened, acted on, or forwarded.
      </div>
      <h4>
        Our uses of these Technologies fall into the following general
        categories:
      </h4>
      <div>
        Operationally Necessary. This includes Technologies that allow you
        access to our Services, applications, and tools that are required to
        identify irregular website behavior, prevent fraudulent activity,
        improve security, or allow you to make use of our functionality.
      </div>
      <p>
        Performance-Related. We may use Technologies to assess the performance
        of our Services, including as part of our analytic practices to help us
        understand how individuals use our Services (see Analytics below);
      </p>
      <div>
        Functionality-Related. We may use Technologies that allow us to offer
        you enhanced functionality when accessing or using our Services. This
        may include identifying you when you sign into our Services or keeping
        track of your specified preferences, interests, or past items viewed;
      </div>
      <p>
        See “Your Privacy Choices and Rights” below to understand your choices
        regarding these Technologies.
      </p>
      <div>
        Analytics. We may use Technologies and other third-party tools to
        process analytics information on our Services. These Technologies allow
        us to better understand how our digital Services are used and to
        continually improve and personalize our Services. Some of our analytics
        partners include:
      </div>
      <p>
        Segment.io. We use Segment's event tracking services to aggregate,
        archive, and distribute website and application usage information. For
        more information about how Segment uses your personal information,
        please visit https://www.twilio.com/legal/privacy.
      </p>
      <p>
        Mixpanel. We use Mixpanel's analytics and event tracking services to
        record and analyze website and application usage. For more information
        about how Mixpanel uses your personal information, please visit{" "}
        <a
          href="https://mixpanel.com/legal/privacy-policy"
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          https://mixpanel.com/legal/privacy-policy
        </a>
        .
      </p>
      <p>
        Twillio. We use Twillio in terms of a or messaging and connecting us the
        users and engaging with them in the pursuit of communicating and
        providing notifications and feedback. Twillio does use your personal
        information and a copy of their privacy policy is here
        https://www.twilio.com/en-us/legal/privacy/
      </p>
      <div>
        Social Media Platforms. Our Service over and beyond being a Video
        platform may contain links and direct connections to social media
        buttons, such as Twitter, YouTube, Linkedin, Instagram, and others in
        the same vein, which might include widgets such as the “share this”
        button or other interactive mini programs). These features may collect
        personal information such as your IP address and which page you are
        visiting on our Services, and may set a cookie to enable the feature to
        function properly. Your interactions with these platforms are governed
        by the privacy policy of the company providing it.
      </div>
      <h4>3. Personal Information Collected from Other Sources</h4>
      <div>
        Third-Party Services and Sources. We may obtain personal information
        about you from other sources, including through third-party services and
        organizations. For example, if you access our Services through a
        third-party application, such as an app store, a third-party login
        service, or a social networking site, we may collect personal
        information about you from that third-party application that you have
        made available via your privacy settings.
      </div>
      <p>
        Referrals and Sharing Features. Our Services may offer various tools and
        functionalities that allow you to provide personal information about
        your friends through our referral service. Third parties may also use
        the Services to upload personal information about you, including when
        they tag you. Our referral services may also allow you to forward or
        share certain content with a friend or colleague, such as an email
        inviting your friend to use our Services. Please only share with us
        contact information of people with whom you have a relationship (e.g.,
        relative, friend, neighbor, or co-worker).
      </p>
      <h3>3. HOW WE USE YOUR PERSONAL INFORMATION</h3>
      <div>
        We use your personal information for a variety of business purposes,
        including to provide our Services, for administrative purposes, and to
        market our products and Services, as described below.
      </div>
      <h4>1. Provide Our Services</h4>
      <div>
        We use your information to fulfill our contract with you and provide you
        with our Services, such as:
      </div>
      <ul style={{ listStyle: "none" }} className="privacy-box">
        <li>Managing your information and accounts;</li>
        <li>
          Providing access to certain areas, functionalities, and features of
          our Services;
        </li>
        <li>Answering requests for customer or technical support;</li>
        <li>
          Communicating with you about your account, activities on our Services,
          and policy changes;
        </li>
        <li>
          Processing your financial information and other payment methods for
          products or Services purchased;
        </li>
        <li>Allowing you to register for events.</li>
      </ul>
      <h4>2. Administrative Purposes</h4>
      <div>
        We use your information for various administrative purposes, such as:
      </div>
      <ul style={{ listStyle: "none" }} className="privacy-box">
        <li>
          Pursuing our legitimate interests such as direct marketing, research
          and development (including marketing research), network and
          information security, and fraud prevention;
        </li>
        <li>
          Detecting security incidents, protecting against malicious, deceptive,
          fraudulent or illegal activity, and prosecuting those responsible for
          that activity;
        </li>
        <li>Measuring interest and engagement in our Services;</li>
        <li>
          Short-term, transient use, such as contextual customization of ads;
        </li>
        <li>Improving, upgrading, or enhancing our Services;</li>
        <li>Developing new products and services;</li>
        <li>Ensuring internal quality control and safety;</li>
        <li>
          Authenticating and verifying individual identities, including requests
          to exercise your rights under this Privacy Policy;
        </li>
        <li>Debugging to identify and repair errors with our Services;</li>
        <li>
          Auditing relating to interactions, transactions, and other compliance
          activities;
        </li>
        <li>
          Sharing personal information with third parties as needed to provide
          the Services;
        </li>
        <li>Enforcing our agreements and policies; and</li>
        <li>
          Carrying out activities that are required to comply with our legal
          obligations.
        </li>
      </ul>
      <h4>3. With Your Consent</h4>
      <div>
        We may use personal information for other purposes that are clearly
        disclosed to you at the time you provide personal information or with
        your consent.
      </div>
      <h4>4. Other Purposes</h4>
      <div>
        We also use your personal information for other purposes as requested by
        you or as permitted by applicable law.
      </div>
      <p>
        De-identified and Aggregated Information. We may use personal
        information to create de-identified and/or aggregated information, such
        as demographic information, information about the device from which you
        access our Services, or other analyses we create.
      </p>
      <h3>4. HOW WE DISCLOSE YOUR PERSONAL INFORMATION</h3>
      <div>
        We disclose your personal information to third parties for a variety of
        business purposes, including to provide our Services, to protect us or
        others, or in the event of a major business transaction such as a
        merger, sale, or asset transfer, as described below.
      </div>
      <h4>1. Disclosures to Provide our Services</h4>
      <div>
        The categories of third parties with whom we may share your personal
        information are described below.
      </div>
      <p>
        Service Providers. We may share your personal information with our
        third-party service providers and vendors that assist us with the
        provision of our Services. This includes service providers and vendors
        that provide us with IT support, hosting, payment processing, customer
        service, and related services.
      </p>
      <div>
        Business Partners. We may share your personal information with business
        partners to provide you with a product or service you have requested. We
        may also share your personal information with business partners with
        whom we jointly offer products or services.
      </div>
      <p>
        Other Users or Third Parties You Share or Interact With. As described
        above in “Personal Information We Collect,” our Services may allow you
        to share personal information or interact with other users and third
        parties (including individuals and third parties who do not use our
        Services and the general public).
      </p>
      <div>
        APIs/SDKs. We may use third-party application program interfaces
        (“APIs”) and software development kits (“SDKs”) as part of the
        functionality of our Services. For more information about our use of
        APIs and SDKs, please contact us as set forth in “ Contact Us” below.
      </div>
      <h4>2. Disclosures to Protect Us or Others</h4>
      <div>
        We may access, preserve, and disclose any information we store
        associated with you to external parties if we, in good faith, believe
        doing so is required or appropriate to: comply with law enforcement or
        national security requests and legal process, such as a court order or
        subpoena; protect your, our, or others’ rights, property, or safety;
        enforce our policies or contracts; collect amounts owed to us; or assist
        with an investigation or prosecution of suspected or actual illegal
        activity.
      </div>
      <h4>
        3. Disclosure in the Event of Merger, Sale, or Other Asset Transfers
      </h4>
      <div>
        If we are involved in a merger, acquisition, financing due diligence,
        reorganization, bankruptcy, receivership, purchase or sale of assets, or
        transition of service to another provider, your information may be sold
        or transferred as part of such a transaction, as permitted by law and/or
        contract.
      </div>
      <h3>5. YOUR PRIVACY CHOICES AND RIGHTS</h3>
      <div>
        Your Privacy Choices. The privacy choices you may have about your
        personal information are determined by applicable law and are described
        below.
      </div>
      <p>
        Email Communications. If you receive an unwanted email from us, you can
        use the unsubscribe link found at the bottom of the email to opt out of
        receiving future emails. Note that you will continue to receive
        transaction-related emails regarding products or Services you have
        requested. We may also send you certain non-promotional communications
        regarding us and our Services, and you will not be able to opt out of
        those communications (e.g., communications regarding our Services or
        updates to our Terms or this Privacy Policy).
      </p>
      <div>
        Mobile Devices. We may send you push notifications through our mobile
        application. You may opt out from receiving these push notifications by
        changing the settings on your mobile device. With your consent, we may
        also collect precise location-based information via our mobile
        application. You may opt out of this collection by changing the settings
        on your mobile device.
      </div>
      <p>
        “Do Not Track.” Do Not Track (“DNT”) is a privacy preference that users
        can set in certain web browsers. Please note that we do not respond to
        or honor DNT signals or similar mechanisms transmitted by web browsers.
      </p>
      <div>
        Your Privacy Rights. In accordance with applicable law, you may have the
        right to:
      </div>
      <p>
        Access to and Portability of Your Personal Information, including: (i)
        confirming whether we are processing your personal information; (ii)
        obtaining access to or a copy of your personal information; and (iii)
        receiving an electronic copy of personal information that you have
        provided to us, or asking us to send that information to another company
        in a structured, commonly used, and machine readable format (also known
        as the “right of data portability”);
      </p>
      <div>
        Request Correction of your personal information where it is inaccurate
        or incomplete. In some cases, we may provide self-service tools that
        enable you to update your personal information;
      </div>
      <p>Request Deletion of your personal information;</p>
      <div>
        Request Restriction of or Object to our processing of your personal
        information where the processing of your personal information is based
        on our legitimate interest or for direct marketing purposes; and
      </div>
      <p>
        Withdraw your Consent to our processing of your personal information.
        Please note that your withdrawal will only take effect for future
        processing, and will not affect the lawfulness of processing before the
        withdrawal.
      </p>
      <div>
        If you would like to exercise any of these rights, please contact us as
        set forth in “Contact Us” below. We will process such requests in
        accordance with applicable laws.
      </div>
      <h3>6. SECURITY OF YOUR INFORMATION</h3>
      <div>
        We take steps to ensure that your information is treated securely and in
        accordance with this Privacy Policy. Unfortunately, no system is 100%
        secure, and we cannot ensure or warrant the security of any information
        you provide to us. To the fullest extent permitted by applicable law, we
        do not accept liability for unauthorized access, use, disclosure, or
        loss of personal information.
      </div>
      <p>
        By using our Services or providing personal information to us, you agree
        that we may communicate with you electronically regarding security,
        privacy, and administrative issues relating to your use of our Services.
        If we learn of a security system’s breach, we may attempt to notify you
        electronically by posting a notice on our Services, by mail, or by
        sending an email to you.
      </p>
      <h3>7. INTERNATIONAL DATA TRANSFERS</h3>
      <div>
        All information processed by us may be transferred, processed, and
        stored anywhere in the world, including, but not limited to, the United
        States or other countries, which may have data protection laws that are
        different from the laws where you live. We endeavor to safeguard your
        information consistent with the requirements of applicable laws.
      </div>
      <p>
        If we transfer personal information which originates in the European
        Economic Area, Switzerland, and/or the United Kingdom to a country that
        has not been found to provide an adequate level of protection under
        applicable data protection laws, one of the safeguards we may use to
        support such transfer is the EU Standard Contractual Clauses.
      </p>
      <div>
        For more information about the safeguards we use for international
        transfers of your personal information, please contact us as set forth
        below.
      </div>
      <h3>8. RETENTION OF PERSONAL INFORMATION</h3>
      <div>
        We store the personal information we collect as described in this
        Privacy Policy for as long as you use our Services, or as necessary to
        fulfill the purpose(s) for which it was collected, provide our Services,
        resolve disputes, establish legal defenses, conduct audits, pursue
        legitimate business purposes, enforce our agreements, and comply with
        applicable laws.{" "}
      </div>
      <h3>9. SUPPLEMENTAL NOTICE FOR NEVADA RESIDENTS</h3>
      <div>
        If you are a resident of Nevada, you have the right to opt-out of the
        sale of certain personal information to third parties who intend to
        license or sell that personal information. You can exercise this right
        by contacting us at{" "}
        <a href="mailto:support@podtoks.com">support@podtoks.com</a> with the
        subject line “Nevada Do Not Sell Request” and providing us with your
        name and the email address associated with your account. Please note
        that we do not currently sell your personal information as sales are
        defined in Nevada Revised Statutes Chapter 603A. If you have any
        questions, please contact us as set forth in Contact Us below.
      </div>
      <h3>10. CHILDREN’S INFORMATION</h3>
      <div>
        The Services are not directed to children under 13 (or other age as
        required by local law), and we do not knowingly collect personal
        information from children.
      </div>
      <p>
        If you are a parent or guardian and believe your child has uploaded
        personal information to our site without your consent, you may contact
        us as described in “Contact Us” below. If we become aware that a child
        has provided us with personal information in violation of applicable
        law, we will delete any personal information we have collected, unless
        we have a legal obligation to keep it, and terminate the child’s
        account.
      </p>
      <h3>11. OTHER PROVISIONS</h3>
      <div>
        Third-Party Websites/Applications. The Services may contain links to
        other websites/applications and other websites/applications may
        reference or link to our Services. These third-party services are not
        controlled by us. We encourage our users to read the privacy policies of
        each website and application with which they interact. We do not
        endorse, screen, or approve, and are not responsible for, the privacy
        practices or content of such other websites or applications. Providing
        personal information to third-party websites or applications is at your
        own risk.
      </div>
      <p>
        Supervisory Authority. If your personal information is subject to the
        applicable data protection laws of the European Economic Area,
        Switzerland, or the United Kingdom, you have the right to lodge a
        complaint with the competent supervisory authority or attorney general
        if you believe our processing of your personal information violates
        applicable law.
      </p>
      <div>
        {" "}
        <a
          href="https://edpb.europa.eu/about-edpb/about-edpb/members_en"
          target="_blank"
        >
          EEA Data Protection Authorities (DPAs)
        </a>
      </div>
      <p>
        <a
          href="https://www.edoeb.admin.ch/edoeb/en/home/deredoeb/kontakt.html"
          target="_blank"
        >
          Swiss Federal Data Protection and Information Commissioner (FDPIC)
        </a>
      </p>
      <div>
        {" "}
        <a href="https://ico.org.uk/global/contact-us/" target="_blank">
          UK Information Commissioner’s Office (ICO)
        </a>
      </div>
      <h3>12. CONTACT US</h3>
      <div>
        PodToks is the controller of the personal information we process under
        this Privacy Policy.
      </div>
      <p>
        If you have any questions about our privacy practices or this Privacy
        Policy, or to exercise your rights as detailed in this Privacy Policy,
        please contact us at: support@podtoks.com
      </p>
    </div>
  );
};

export default Privacynew;
