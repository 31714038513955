// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.review-container{
    background-color: white;
    padding: 6.7em 0;
}
.review-slider{
    margin: 0 auto;
    width: 54%;
}
.review-h2{
    font-size: 42px;
    font-family: 'Poppins',sans-serif;
    text-align: center;
    font-weight: 500;
}
.review-div{
    padding-top: 2em;
    text-align: center;
}
.review-image{
    width: 150px;
    height: 150px;
    margin: auto;
    padding: 0.5em;
}
.review-p{
    width: 64%;
    font-size: 17px;
    line-height: 30px;
    font-family: 'Poppins',sans-serif;
    font-weight: 300;
    margin: 0 auto;
}
.review-star{
    margin-top: 2em;
}
.review-inside-h4{
    font-size: 30px;
    margin-top: 0.5em;
    color: #008DA6;
    padding-bottom: 2px;
    font-family: 'Poppins',sans-serif;
    font-weight: 400;
}
@media (max-width: 800px) {
    .review-slider{
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/css/Review.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,UAAU;AACd;AACA;IACI,eAAe;IACf,iCAAiC;IACjC,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,cAAc;AAClB;AACA;IACI,UAAU;IACV,eAAe;IACf,iBAAiB;IACjB,iCAAiC;IACjC,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,eAAe;AACnB;AACA;IACI,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,mBAAmB;IACnB,iCAAiC;IACjC,gBAAgB;AACpB;AACA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":[".review-container{\n    background-color: white;\n    padding: 6.7em 0;\n}\n.review-slider{\n    margin: 0 auto;\n    width: 54%;\n}\n.review-h2{\n    font-size: 42px;\n    font-family: 'Poppins',sans-serif;\n    text-align: center;\n    font-weight: 500;\n}\n.review-div{\n    padding-top: 2em;\n    text-align: center;\n}\n.review-image{\n    width: 150px;\n    height: 150px;\n    margin: auto;\n    padding: 0.5em;\n}\n.review-p{\n    width: 64%;\n    font-size: 17px;\n    line-height: 30px;\n    font-family: 'Poppins',sans-serif;\n    font-weight: 300;\n    margin: 0 auto;\n}\n.review-star{\n    margin-top: 2em;\n}\n.review-inside-h4{\n    font-size: 30px;\n    margin-top: 0.5em;\n    color: #008DA6;\n    padding-bottom: 2px;\n    font-family: 'Poppins',sans-serif;\n    font-weight: 400;\n}\n@media (max-width: 800px) {\n    .review-slider{\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
