// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.what-container{
    width: 100%;
}
.inside-what{
    padding-left: 150px;
    padding-right: 150px;
    padding-top: 20px;
    padding-bottom: 50px;
}
.inside-div{
    display: grid;
    grid-template-columns: 5fr 5fr;
    grid-gap: 2em;
}
.what-right{
    display: flex;
    align-items: center;
    justify-content: center;
}
.what-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 20px;
}
.what-left h2{
    font-size: 50px;
    font-family: 'Poppins',sans-serif;
    font-weight: 500;
}
.what-left p{
    font-size: 18px;
    font-family: 'Poppins',sans-serif;
    font-weight: 300;
}
@media (max-width:768px) {
    .inside-div{
        display: flex;
        flex-direction: column;
        align-items: center;

    }
    
    .what-left h2{
        font-size: 30px;
    }
    .what-left p{
        font-size: 14px;
    }
    .what-left{
        width: 300px;
    }
    .what-right{
        width: 300px;
    }
}`, "",{"version":3,"sources":["webpack://./src/css/What.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;AACA;IACI,mBAAmB;IACnB,oBAAoB;IACpB,iBAAiB;IACjB,oBAAoB;AACxB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,aAAa;AACjB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,2BAA2B;IAC3B,iBAAiB;AACrB;AACA;IACI,eAAe;IACf,iCAAiC;IACjC,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,iCAAiC;IACjC,gBAAgB;AACpB;AACA;IACI;QACI,aAAa;QACb,sBAAsB;QACtB,mBAAmB;;IAEvB;;IAEA;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;IACA;QACI,YAAY;IAChB;IACA;QACI,YAAY;IAChB;AACJ","sourcesContent":[".what-container{\n    width: 100%;\n}\n.inside-what{\n    padding-left: 150px;\n    padding-right: 150px;\n    padding-top: 20px;\n    padding-bottom: 50px;\n}\n.inside-div{\n    display: grid;\n    grid-template-columns: 5fr 5fr;\n    grid-gap: 2em;\n}\n.what-right{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n.what-left {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    justify-content: flex-start;\n    padding-top: 20px;\n}\n.what-left h2{\n    font-size: 50px;\n    font-family: 'Poppins',sans-serif;\n    font-weight: 500;\n}\n.what-left p{\n    font-size: 18px;\n    font-family: 'Poppins',sans-serif;\n    font-weight: 300;\n}\n@media (max-width:768px) {\n    .inside-div{\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n\n    }\n    \n    .what-left h2{\n        font-size: 30px;\n    }\n    .what-left p{\n        font-size: 14px;\n    }\n    .what-left{\n        width: 300px;\n    }\n    .what-right{\n        width: 300px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
