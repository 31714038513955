import React from "react";
import "../css/Video.css";

const Video = () => {
  return (
    <div className="video-section">
      <h2 style={{}}>Experience PodToks For Yourself</h2>
      <div className="back-seeyour">
        <img
          src="https://zukaz.com/assets/img/Video-Screen-Image_01A.jpg"
          className="back-video"
        />
      </div>
    </div>
  );
};

export default Video;
