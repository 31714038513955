// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.creator-main{
    display: flex;
}
.creator-first{
    width: 50%;
    height: 300px;
    background-color: #fab17a;
    display: flex;
    justify-content: center;
    align-items: center;
}
.creator-second{
    width: 50%;
    height: 300px;
    background-color: #008da6;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (max-width: 768px) {
    .creator-main{
        flex-direction: column;
    }
    .creator-first{
        width: 100%;
    }
    .creator-second{
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/About/Creator.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,UAAU;IACV,aAAa;IACb,yBAAyB;IACzB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,UAAU;IACV,aAAa;IACb,yBAAyB;IACzB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI;QACI,sBAAsB;IAC1B;IACA;QACI,WAAW;IACf;IACA;QACI,WAAW;IACf;AACJ","sourcesContent":[".creator-main{\n    display: flex;\n}\n.creator-first{\n    width: 50%;\n    height: 300px;\n    background-color: #fab17a;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n.creator-second{\n    width: 50%;\n    height: 300px;\n    background-color: #008da6;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n@media (max-width: 768px) {\n    .creator-main{\n        flex-direction: column;\n    }\n    .creator-first{\n        width: 100%;\n    }\n    .creator-second{\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
