import React from "react";
import "../css/Image.css";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import Image from "../assets/zukaz_hero_orange (2).png";
import Phone from "../assets/20231206_155315-removebg-preview.png";

const Imagesection = () => {
  const [typeEffect] = useTypewriter({
    words: ["Educators", "Podcasters", "Creators", "Believers"],
    loop: {},
    typeSpeed: 100,
    deleteSpeed: 40,
  });
  return (
    <div>
      <div className="image-detail">
        <img src={Image} alt="image" className="image-detail-inside" />
      </div>

      <div className="header-banner">
        <div className="banner-left">
          <h1>
            Learn and Engage <br /> On Topics You Care About
          </h1>
          <span>
            And Created For:
            <strong style={{ marginLeft: "10px", color: "#792dd7" }}>
              {typeEffect}
            </strong>
          </span>
        </div>

        <div className="banner-right">
          <div className="phone-hand">
            <img
              src={Phone}
              alt="podtoks"
              title="podtoks"
              style={{
                width: "100%",
                position: "relative",
                marginTop: "50px",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Imagesection;
