import React from "react";
import "../css/What.css";
import Backtotop from "./Backtotop";
import ImageF from "../assets/20231207_160027-removebg-preview.png";
import ImageS from "../assets/20231207_155420-removebg-preview.png";

const What = () => {
  return (
    <div
      className="what-container"
      style={{ backgroundColor: "#CBC3E3", height: "max-content" }}
    >
      <div className="inside-what">
        <div className="inside-div">
          {/* what-left */}
          <div className="what-left">
            <h2>What is PodToks?</h2>
            <p style={{ width: "92%", textAlign: "justify" }}>
              PodToks offers a unique social media experience, specifically
              designed for those seeking knowledge across various fields. It
              serves educators, coaches, creators, podcasters, and a broad
              audience by hosting a platform for sharing one-minute short
              videos. The design of PodToks enhances content discoverability,
              enabling users to easily find and delve into a wide array of
              topics within their interest areas.
            </p>
            <p style={{ width: "92%", textAlign: "justify" }}>
              The platform embraces microlearning and micro-discovery trends,
              breaking content into small, digestible units. This format
              simplifies the process of finding, understanding, and remembering
              information, especially for complex subjects or extensive podcasts
              that are challenging in traditional formats. Additionally, PodToks
              employs engaging visuals and interactive features, accommodating
              different learning styles and making education more inclusive and
              engaging.
            </p>
          </div>
          {/* what-right */}
          <div className="what-right">
            <img
              src={ImageF}
              alt=""
              width="95%"
              // height={436}
              className="what-image"
            />
            <img src={ImageS} alt="" width="95%" className="what-image" />
          </div>
        </div>
      </div>
      <Backtotop />
    </div>
  );
};

export default What;
