import React from "react";
import "./Header.css";

const Header = () => {
  return (
    <div>
      <div className="image-detail-about">
        <img
          src="https://zukaz.com/assets/img/Zukaz-Main-about-us.png"
          alt="image"
          className="image-detail-inside"
        />
      </div>

      <div className="header-banner">
        <div
          className="banner-left-about"
          style={{ marginLeft: "6vw", marginTop: "4vw" }}
        >
          <div style={{ fontSize: "50px", fontWeight: "bold" }}>
            About Podtok
          </div>
          <div style={{ fontSize: "20px" }}>
            Get to know us a little better.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
