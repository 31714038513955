import React from "react";
import "./Teamup.css";

const Teamup = () => {
  return (
    <div className="profit-main" style={{ marginBottom: "6vw" }}>
      <div className="profit-second">
        <div className="teamup-box-one">
          <h2 style={{ fontSize: "40px" }}>Let’s team up</h2>
          <p style={{ fontSize: "20px", marginTop: "-10px" }}>
            Step up your marketing game and increase sales today with the power
            of AR.
          </p>
        </div>
        <div className="teamup-box">
          <div className="teamup-box-inside">
            <div>
              <img
                src="https://zukaz.com/assets/img/icon_customer.png"
                alt=""
                width={200}
                height={200}
              />
            </div>
            <div>
              <h2>Collect consumer and voucher analytics</h2>
              <p>
                Gain stronger clarity in customer behaviour and understand how
                your customers navigate and spend time within retail
                environments.
              </p>
            </div>
          </div>
        </div>
        <div className="teamup-box">
          <div className="teamup-box-inside">
            <div>
              <img
                src="https://zukaz.com/assets/img/teamup-2.png"
                alt=""
                width={200}
                height={200}
              />
            </div>
            <div>
              <h2>Collect consumer and voucher analytics</h2>
              <p>
                Gain stronger clarity in customer behaviour and understand how
                your customers navigate and spend time within retail
                environments.
              </p>
            </div>
          </div>
        </div>
        <div className="teamup-box">
          <div className="teamup-box-inside">
            <div>
              <img
                src="https://zukaz.com/assets/img/teamup-3.png"
                alt=""
                width={200}
                height={200}
              />
            </div>
            <div>
              <h2>Collect consumer and voucher analytics</h2>
              <p>
                Gain stronger clarity in customer behaviour and understand how
                your customers navigate and spend time within retail
                environments.
              </p>
            </div>
          </div>
        </div>
        <div className="teamup-box">
          <div className="teamup-box-inside">
            <div>
              <img
                src="https://zukaz.com/assets/img/teamup-4.png"
                alt=""
                width={200}
                height={200}
              />
            </div>
            <div>
              <h2>Collect consumer and voucher analytics</h2>
              <p>
                Gain stronger clarity in customer behaviour and understand how
                your customers navigate and spend time within retail
                environments.
              </p>
            </div>
          </div>
        </div>
        <div className="teamup-box">
          <div className="teamup-box-inside">
            <div>
              <img
                src="https://zukaz.com/assets/img/teamup-5.png"
                alt=""
                width={200}
                height={200}
              />
            </div>
            <div>
              <h2>Collect consumer and voucher analytics</h2>
              <p>
                Gain stronger clarity in customer behaviour and understand how
                your customers navigate and spend time within retail
                environments.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Teamup;
