import React from "react";

const Started = () => {
  return (
    <div style={{ background: "white", width: "100%" }}>
      <div className="main-work">
        <div className="work">
          <h2>Getting started</h2>
          <div className="list-work">
            <div className="inside-work">
              <div>
                <img
                  src="https://zukaz.com/assets/img/icon_register.png"
                  alt=""
                  width={200}
                  height={200}
                  className="work-image"
                />
              </div>
              <div className="div-work">
                <h2
                  style={{
                    marginTop: "1em",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                    textAlign: "left",
                  }}
                >
                  1. Register
                </h2>
                <p className="work-text">Sign up and get ready to drop!</p>
              </div>
            </div>
            {/* second item */}
            <div className="inside-work">
              <div>
                <img
                  src="https://zukaz.com/assets/img/icon_dropvoucher.png"
                  alt=""
                  width={200}
                  height={200}
                  className="work-image"
                />
              </div>
              <div className="div-work">
                <h2
                  style={{
                    marginTop: "1em",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                    textAlign: "left",
                  }}
                >
                  2. Drop a voucher
                </h2>
                <p className="work-text">
                  Decide the cost, location and the number of vouchers to drop.
                  (We’ll only take 10% upon redemption.)
                </p>
              </div>
            </div>
            {/* third item */}
            <div className="inside-work">
              <div>
                <img
                  src="https://zukaz.com/assets/img/icon_getready.png"
                  alt=""
                  width={200}
                  height={200}
                  className="work-image"
                />
              </div>
              <div className="div-work">
                <h2
                  style={{
                    marginTop: "1em",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                    textAlign: "left",
                  }}
                >
                  3. Get ready
                </h2>
                <p className="work-text">
                  Collected vouchers will be claimed in-store by your customers.
                </p>
              </div>
            </div>
            {/* fourth item */}
            <div className="inside-work">
              <div>
                <img
                  src="https://zukaz.com/assets/img/icon_customer.png"
                  alt=""
                  width={200}
                  height={200}
                  className="work-image"
                />
              </div>
              <div className="div-work">
                <h2
                  style={{
                    marginTop: "1em",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                    textAlign: "left",
                  }}
                >
                  4. Know your customer
                </h2>
                <p className="work-text">
                  Learn the ins and outs of your consumers, through voucher
                  performance!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Started;
