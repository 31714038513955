import React from "react";

const Body = () => {
  return (
    <div className="profit-main">
      <div className="profit-second-privacy">
        <div className="privacy-main">
          <div className="privacy-first">
            <h3>COMMUNITY GUIDELINES</h3>
          </div>
          <div className="privacy-second">
            <h2>COMMUNITY GUIDELINES</h2>
            <h3>
              PodToks platform has certain community guidelines we expect all
              users of the app to adhere to.
            </h3>
            <div>
              Thanks for being a member of the PodToks community! PodToks
              mission is to provide a service that is suitable for all types of
              users, that values freedom of expression and the acceptance of a
              diverse range of ideas and opinions, and that enables its users to
              “share, create and connect” in a lawful, safe, inclusive, and
              supportive manner.
            </div>
            <p>
              PodToks has established these Community Guidelines to help it
              fulfill this mission and to set forth certain activity and types
              of content that users are prohibited from engaging in and/or
              uploading to the service in order to ensure that PodToks remains a
              safe, friendly, and a fun place to create amazing and original
              content.
            </p>
            <div>
              PodToks reserves the right to remove certain content from the
              service and/or take additional actions against users of the
              service who violate these Community Guidelines, including the
              right to monitor and report violations of these Community
              Guidelines to the applicable authorities. The consequences for
              violating these Community Guidelines depend on various factors,
              including, but not limited to, the nature and the severity of the
              violation and any other aggravating or relevant factors.
            </div>
            <p>
              These Community Guidelines supplement the PodToks{" "}
              <a href="https://podtoks.com/termsofservice" target="_blank">
                Term Of Service
              </a>
               , which are incorporated by reference herein. For more
              information, please visit the{" "}
              <a href="https://podtoks.com/termsofservice">Term Of Service</a>.{" "}
              <br /> If you would like to report a violation of these Community
              Guidelines to PodToks, please email support@podtoks.com.
            </p>
            <h3>Bullying, Harassment or Threats</h3>
            <p>
              PodToks strictly prohibits abusive behavior that is intended to
              harass, bully, intimidate, threaten, or degrade other users either
              on or off the platform. Bullying and harassment include a wide
              variety of unwanted type of behavior and conduct. This includes,
              without limitation, making unwanted malicious contact with another
              user, threatening another user, or intending to inflict harm or
              distress on another user, including physical, psychological, or
              social harm.
            </p>
            <h3>Do not:</h3>
            <div>
              Send abusive, offensive, or threatening messages or comment on a
              user’s posts or user profile with abusive language or insults.
            </div>
            <p>
              Incite or encourage other users to harass, bully or threatened a
              user, an individual or a group of people.
            </p>
            <div>
              Threaten another person’s physical safety in a manner that creates
              a reasonable fear of injury or harm.
            </div>
            <p>
              Express a desire for a particular user, an individual or a group
              of people to hurt or kill themselves.
            </p>
            <div>
              Make unwanted and unsolicited sexual advances, sexual remarks,
              request for sexual favors to another user.
            </div>
            <p>
              Threaten to release a person’s personal information without their
              consent with the intent to harm them, commonly referred to as
              doxing.
            </p>
            <div>
              Content that relates to bullying and harassment is allowed if it
              serves an educational, documentary, scientific, artistic, or
              activist purpose, such as posting content that brings awareness to
              the effects of cyberbullying or harassment by using actual or
              simulated examples as an illustration or that is artistic or
              comedic in nature.
            </div>
            <h3>Hate Speech and Depictions of Hateful Conduct</h3>
            <div>
              Triller prohibits any content or behavior that promotes violence,
              hatred, discrimination, or denigration on another person or group
              of people on the basis of race, color, ethnicity, national origin
              or ancestry, sex, age, disabilities, veteran status, genetic
              information, citizenship, gender identity, sexual orientation,
              religious affiliation, or diseases. We define people or a group of
              people with any of those attributes as a member or members of a
              “protected class”.
            </div>
            <h3>Do not:</h3>
            <div>
              Post content which encourages, promotes, or praises violence
              against another user or group of people because they are a member
              or members of a protected class Post, message or comment any
              content containing slurs or stereotypes with the purpose of
              degrading or promoting hatred or a harmful stereotype of a member
              or members of a protected class. <br /> Post content which
              contains hateful imagery (logos, symbols or images) with the
              intent to promote or incite hatred towards a certain protected
              class. <br /> Post content which contains dehumanizing speech,
              statements of inferiority, calls for discrimination, exclusion or
              segregation. <br />
              Post content which promotes hateful ideologies against one or more
              protected classes
            </div>
            <h3>Sexually Explicit Content</h3>
            <div>
              Triller restricts content containing nudity and adult content. We
              define nudity as full or partial nudity, including close-ups of
              genitals, buttocks, or breasts (excluding content related to
              breastfeeding). We also restrict any content that includes any
              sexual intercourse, simulated sexual acts, and other sexual
              activities.
            </div>
            <h3>Do not:</h3>
            <div>
              Post content containing human genitalia, female nipples, or
              buttocks
            </div>
            <div>
              Post content the depicts sexual intercourse, masturbation, or
              other sexual activity, including non-penetrative or oral sex
              (whether actual, simulated or dramatized)
            </div>
            <div>Post content which contains sexual activities</div>
            <div>
              Post content which depicts sexual exploitation or sexual abuse of
              another (whether actual, simulated or dramatized)
            </div>
            <div>
              Post content, advertisement or links to pornographic, escort or
              erotic websites and services
            </div>
            <h3>Violent and Graphic Content and Illegal Activities</h3>
            <div>
              Triller does not allow content that is excessively gruesome,
              shocking or contains or promotes illegal activities. In certain
              circumstances, we allow graphic content to be displayed within our
              platform to bring awareness about the issue.
            </div>
            <h3>Do not:</h3>
            <div>
              Post content which shows graphic violence, torture, or death of a
              human (whether actual, simulated or dramatized)
            </div>
            <div>
              Post content which shows yourself or another individual inflicting
              self-harm or committing suicide (whether actual, simulated or
              dramatized)
            </div>
            <div>Post content which promotes suicide or self-harm</div>
            <div>Post content which would shock or disgust other users</div>
            <div>
              Post content to incite others to engage in criminal conduct or
              violent acts against others
            </div>
            <div>
              Post content celebrating or praising the glorification of violence
              or crime
            </div>
            <div>
              Post content which shows graphic violence, torture, or death of an
              animal
            </div>
            <div>
              Post, message or comment any content featuring, encouraging, or
              soliciting illegal activity
            </div>
            <p>
              In certain cases, violent or graphic material is allowed where it
              serves an educational, documentary, scientific, artistic, or
              activist purpose, such as posting content that shows the harm of
              police brutality, sharing personal stories of a person’s self-harm
              to educate others about mental health issues and suicide
              prevention, or displaying staged or organized martial arts
              fighting.
            </p>
            <h3>Safety of Minors</h3>
            <div>
              Triller has a zero-tolerance policy regarding content or conduct
              that endangers the safety of minors (anyone younger than 18 years
              old). Any content which contains or promotes child sexual
              exploitation or otherwise engagers children will be reported to
              the appropriate law enforcement agency.
            </div>
            <h3>Do not:</h3>
            <div>
              Post any content that shows a minor in a state of nudity (showing
              human genitalia, female nipples, or buttocks)
            </div>
            <div>
              Content which depicts any person who appears to be under the age
              of eighteen (18) engaging in actual or simulated:
            </div>
            <div>(1) sexual intercourse;</div>
            <div>
              (2) bestiality, masturbation, sadistic or masochistic abuse; or
            </div>
            <div>
              (3) exhibition of genitals or a person’s pubic area in a sexual
              manner.
            </div>
            <p>
              This includes images that have been adapted or modified to make it
              appear that a minor under the age of 18 is engaged in such
              conduct.
            </p>
            <div>
              Post third-party links to sites that host child pornography or
              other child sexual exploitation materials
            </div>
            <div>
              Solicit, encourage or express an interest in having sexual
              intercourse (including non- penetrative or oral sex) with a minor
              or any other sexual activity
            </div>
            <div>
              Solicit, encourage or express an interest transporting a minor for
              sexual purposes
            </div>
            <div>
              Post content which depicts, promotes or encourages minors to
              participate in dangerous or illegal activity , including the
              consumption or use of drugs and/or alcohol
            </div>
            <h3>Misleading Information, Impersonation and Spam</h3>
            <div>
              While Triller encourage its users to express themselves freely, we
              prohibit any information that is intentionally misleading or done
              with malice that may present harm to the other users and the
              public.
            </div>
            <h3>Do not:</h3>
            <div>
              Use another person’s photo, name or likeness as your profile
              picture
            </div>
            <div>
              User another person’s photo, name or likeness in connection with
              any content you upload to the service without that person’s
              permission
            </div>
            <div>
              Use another person’s content unless that content has been properly
              licensed or is otherwise used with express permission of such
              person (or the applicable rights holder of such content) – if it’s
              not yours, don’t upload it
            </div>
            <div>
              Falsely represent an affiliation to a particular candidate,
              elected official, political party or government entity
            </div>
            <div>
              Intentionally post false or misleading information to threaten the
              integrity of the electoral process
            </div>
            <div>
              Intentionally post false or misleading information which may cause
              physical or psychological harm or fear to the public or other
              users
            </div>
            <div>Impersonate another individual or organization</div>
            <div>
              Engage in or solicit any phishing or any fraudulent behavior
            </div>
            <div>
              Post spam content or material or direct other users to external
              third-party sites
            </div>
            <div>
              Post content not related to the platform or repetitive
              comments/content/messages across multiple profiles
            </div>
            <div>Engage in excessive emoji/icon spam</div>
            <h3>Unauthorized Sharing of Private Information</h3>
            <div>
              Triller respects the privacy of not only your account but other
              individuals as well. For that reason, we prohibit our users from
              revealing or exposing someone’s private information without their
              consent or violating someone’s personal privacy.
            </div>
            <h3>Do not:</h3>
            <div>
              Share personally identifiable information (such as another
              person’s real name, location, photo or identification)
            </div>
            <div>
              Post or share any restricted or protected social profiles
              including information from those profiles
            </div>
            <div>
              Share content that violates another’s reasonable expectation of
              privacy, for example streaming from a private space, without
              permission.
            </div>
            <h3>Misleading Metadata</h3>
            <div>
              Metadata refers to any and all additional information provided on
              an item of content uploaded to the service, such as the title,
              description, tags, annotations, copyright information, thumbnail,
              author/owner attribution, etc.
            </div>
            <h3>Do not:</h3>
            <p>
              Include metadata that is not actually representative of the
              content in which it is contained or that is otherwise false or
              misleading. Insert false or misleading metadata to game search
              algorithms (doing this may lead to the removal of your content and
              possible suspension or termination of your account)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
