import React from "react";
import "./Creator.css";
import { Link } from "react-router-dom";

const Creator = () => {
  return (
    <div className="creator-main">
      <div className="creator-first">
        <Link to="/" style={{ textDecoration: "none" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src="https://zukaz.com/assets/img/face-left.png" alt="" />
            <h2 style={{ color: "white", fontSize: "30px" }}>I’m a Hunter</h2>
          </div>
        </Link>
      </div>
      <div className="creator-second">
        <Link to="/business" style={{ textDecoration: "none" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src="https://zukaz.com/assets/img/face-right.png" alt="" />
            <h2
              style={{
                color: "white",
                fontSize: "30px",
              }}
            >
              I’m a Business
            </h2>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Creator;
