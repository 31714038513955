import React from "react";
import "./Customer.css";
import { useTypewriter, Cursor } from "react-simple-typewriter";

const Customer = () => {
  const [typeEffect] = useTypewriter({
    words: ["Customer", "time", "place", "moment"],
    loop: {},
    typeSpeed: 100,
    deleteSpeed: 40,
  });
  return (
    <section className="customer-main">
      <img
        src="https://zukaz.com/assets/img/line-img-footer.png"
        alt=""
        className="image-first-customer"
      />
      <div>
        <img
          src="	https://zukaz.com/assets/img/Rectangle_121.png"
          alt=""
          className="image-second-customer"
        />
      </div>
      <img
        src="https://zukaz.com/assets/img/balloon_blue.png"
        alt=""
        className="image-third-customer"
      />
      <div className="animation-customer">
        <h2
          style={{ fontSize: "35px", fontWeight: "500", marginBottom: "0px" }}
        >
          It's time your <br />
          customers chase you
        </h2>
        <span style={{ marginTop: "-20px", fontSize: "20px" }}>
          Drop a voucher at the right:
          <strong style={{ marginLeft: "10px" }}>{typeEffect}</strong>
        </span>
      </div>
    </section>
  );
};

export default Customer;
