import React, { useState, useEffect } from "react";
import "../css/Navbar.css";
import { Link } from "react-router-dom";
import Image from "../assets/podtokpink2_in_white-removebg-preview.png";

const Navbar = () => {
  const [scrolling, setScrolling] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleMenuToggle = () => {
    setShowMenu(!showMenu);
  };

  return (
    <nav className={`navbar ${scrolling ? "scrolling" : ""}`}>
      <div className="navbar-logo">
        <a href="/">
          <img src={Image} alt="" width={100} height={60} />
        </a>
      </div>
      <div className={`navbar-list ${showMenu ? "show" : ""}`}>
        <ul>
          <li>
            <Link to="/" style={{ textDecoration: "none", color: "black" }}>
              <a href="#section1">I'm a Hunter</a>
            </Link>
          </li>
          <li>
            <a href="#section2">Support</a>
          </li>
          {/* <li>
            <a href="#section3">About</a>
          </li> */}
        </ul>
      </div>
      <div className={`navbar-button ${scrolling ? "hide" : ""}`}>
        <button>See it in Action</button>
      </div>
      <div className="navbar-hamburger" onClick={handleMenuToggle}>
        <div className={`line ${showMenu ? "open" : ""}`}></div>
        <div className={`line ${showMenu ? "open" : ""}`}></div>
        <div className={`line ${showMenu ? "open" : ""}`}></div>
      </div>
    </nav>
  );
};

export default Navbar;
