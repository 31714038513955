import React from "react";

const Video = () => {
  return (
    <section className="video-section">
      <div className="back-seeyour-business">
        <video
          src="https://s3-ap-southeast-2.amazonaws.com/zukaz-media/video/ZUKAZVOv.226.04.19.mp4"
          controls
          width="640" // Set a width for the video element
          height="360"
          //className="back-video"
        ></video>
      </div>
    </section>
  );
};

export default Video;
