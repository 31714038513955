import React from "react";
import Image from "../assets/PodToks_Section.png";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Apple from "../assets/214380067-removebg-preview.png";

const Download = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        background: "#5d3b8b",
        paddingBottom: "30px",
      }}
    >
      <h2
        className="text-center"
        style={{
          textAlign: "center",
          color: "white",
          fontWeight: "bold",
          fontSize: "50px",
          marginBottom: "15px",
        }}
      >
        Download Our App
      </h2>
      <div style={{ display: "flex", gap: "30px", justifyContent: "center" }}>
        <img
          src="https://static.vecteezy.com/system/resources/previews/024/170/871/non_2x/badge-google-play-and-app-store-button-download-free-png.png"
          alt=""
          width={200}
          height={70}
          id="Download"
        />
        <ReactTooltip anchorId="Download" place="top" content="Coming Soon" />
        <img src={Apple} alt="" width={200} height={70} id="apple" />
        <ReactTooltip anchorId="apple" place="top" content="Coming Soon" />
      </div>
    </div>
    // <div
    //   style={{ marginBottom: "-4px", width: "100%", overflow: "hidden" }}
    //   id="Download"
    // >
    //   <img
    //     src={Image}
    //     alt=""
    //     width="110%"
    //     height="100%"
    //     style={{ objectFit: "contain" }}
    //     id="Download"
    //   />
    //   <ReactTooltip anchorId="Download" place="top" content="Coming Soon" />
    // </div>
  );
};

export default Download;
